import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import reportsService from "../../../service/reportsService";
import StudentsTable from "./StudentsTable";
import BirthdaysHeader from "./BirthdaysHeader";

const monthOrder = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Birthdays() {
  const params = useParams();
  const [schoolName] = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      reportsService
        .birthdays({
          school_year_id: params.school_year_id,
        })
        .then((response) => {
          setStudents(response.students);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    })();
  }, [params.school_year_id]);

  const sortedKeys = Object.keys(students).sort(
    (a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b)
  );

  const generateBodyContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <Typography color="black" fontSize="20px" mt={5}>
            Loading...
          </Typography>
        </Box>
      );
    }

    if (Object.keys(students).length === 0) {
      return (
        <tr>
          <td>
            <Box textAlign="center" mt={3}>
              No birthdays found.
            </Box>
          </td>
        </tr>
      );
    }

    return sortedKeys.map((month) => (
      <tr key={month} className="report-data">
        <td
          className="report-content-cell"
          style={{ padding: "0.5rem 1rem 0 3.5rem" }}
        >
          <StudentsTable monthName={month} students={students[month]} />
        </td>
      </tr>
    ));
  };

  return (
    <PrintLayout
      headerContent={<BirthdaysHeader content="Birthdays" />}
      bodyContent={generateBodyContent()}
      footerContent={schoolName}
    />
  );
}
