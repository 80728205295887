import React, { useState, useEffect } from "react";
import { Drawer, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  closeIconStyle,
  changeSchoolsName,
  drawerContainer,
  leftDrawerStyle,
  changeSchoolsTitle,
  changeSchoolsListRow,
  changeSchoolsNameActive,
  changeSchoolsActiveLink,
} from "./LeftDrawer.style";
import { mt35 } from "./sharedStyles";
import teachersService from "../service/staffsService";

function LeftDrawer({
  drawerStatus,
  toggleDrawer,
  teacher,
  defaultSchoolSlug,
  schoolId,
}) {
  const [schools, setSchools] = useState([]);

  const getSchools = async () => {
    const response = await teachersService.fetchSchools(teacher.id);
    if (response.data) {
      setSchools(response.data.schools);
    }
  };

  useEffect(() => {
    getSchools();
  }, []);

  return (
    <Drawer open={drawerStatus} sx={leftDrawerStyle}>
      <CloseIcon onClick={toggleDrawer(false)} sx={closeIconStyle} />
      <Box sx={drawerContainer}>
        <Typography sx={changeSchoolsTitle}>Change Schools</Typography>

        <Box sx={mt35}>
          {schools.map((school) => {
            const activeSchool = schoolId === school.slug;

            return (
              <Box sx={changeSchoolsListRow} key={school.name}>
                <Box
                  onClick={() =>
                    window.location.replace(
                      `/school/${school.slug}/entry-screen`
                    )
                  }
                  sx={activeSchool ? changeSchoolsActiveLink : {}}
                >
                  <Typography
                    sx={
                      activeSchool ? changeSchoolsNameActive : changeSchoolsName
                    }
                  >
                    {`${school.name} ${
                      defaultSchoolSlug === school.slug ? "(Default)" : ""
                    }`}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Drawer>
  );
}

export default LeftDrawer;
