import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  font28,
  my30,
  loader,
  font20,
  font14,
  switchBtn,
} from "../sharedStyles";
import SnackBarNotification from "../SnackBarNotification";
import schoolsService from "../../service/schoolsService";

export default function GradebookSettings({ managePermission }) {
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [gradebookVersion, setGradebookVersion] = useState("v1");
  const params = useParams();
  const schoolId = params.school_id;

  const updateGradebookVersion = async (newVersion) => {
    setLoading(true);
    try {
      const response = await schoolsService.updateSchoolGradebookVersion(
        schoolId,
        newVersion
      );
      const school = response;
      setGradebookVersion(school.gradebook_version);
      setLoading(false);
      setSnackBarOpen({
        open: true,
        message: "Saved successfully",
        severity: "success",
      });
    } catch (error) {
      setLoading(false);
      setSnackBarOpen({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleSwitch = (checked, version) => {
    let newVersion = "";

    if (checked) {
      if (
        (version === "v1" && gradebookVersion !== "v1") ||
        (version === "v2" && gradebookVersion !== "v2")
      ) {
        newVersion = "both";
      } else {
        newVersion = version;
      }
    } else if (version === "v1" && gradebookVersion !== "v2") {
      newVersion = "v2";
    } else if (version === "v2" && gradebookVersion !== "v1") {
      newVersion = "v1";
    } else if (
      (version === "v1" && gradebookVersion === "v2") ||
      (version === "v2" && gradebookVersion === "v1")
    ) {
      newVersion = "both";
    }
    return newVersion;
  };

  const handleGradebookVersionChange = (event, version) => {
    const { checked } = event.target;

    const newVersion = handleSwitch(checked, version);

    setGradebookVersion(newVersion);
    updateGradebookVersion(newVersion);
  };

  const getSchoolInfo = async () => {
    setLoading(true);
    try {
      const response = await schoolsService.fetchSchool(schoolId);
      const school = response.data;
      setGradebookVersion(school.gradebook_version);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackBarOpen({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    getSchoolInfo();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={{ ...my30, justifyContent: "center" }}>
      <Typography sx={font28}>Gradebook</Typography>
      <Grid container spacing={2} ml={10} mt={2}>
        <Grid container item xs={12}>
          <Grid item xs={10}>
            <Typography sx={font20} color="#000000">
              Gradebook V.1
            </Typography>
            <Typography sx={font14} color="#000000">
              Original gradebook displays assignments by week.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              sx={{ pt: "10px" }}
              control={
                <Switch
                  disabled={!managePermission}
                  sx={switchBtn}
                  checked={
                    gradebookVersion === "v1" || gradebookVersion === "both"
                  }
                  onChange={(e) => {
                    handleGradebookVersionChange(e, "v1");
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mt={2}>
          <Grid item xs={10}>
            <Typography sx={font20} color="#000000">
              Gradebook V.2
            </Typography>
            <Typography sx={font14} color="#000000">
              Displays all assignments for a select term.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              sx={{ pt: "10px" }}
              control={
                <Switch
                  disabled={!managePermission}
                  sx={switchBtn}
                  checked={
                    gradebookVersion === "v2" || gradebookVersion === "both"
                  }
                  onChange={(e) => {
                    handleGradebookVersionChange(e, "v2");
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Box>
  );
}
