import React, { useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { pageTabs } from "../../sharedStyles";

export default function EnrollmentsTabs() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const schoolId = params.school_id;
  const schoolYearIDPath = params.school_year_id;

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    return paramsUrl;
  };

  const tabs = [
    {
      index: 0,
      label: "Enrolled Students",
      url: `/school/${schoolId}/${schoolYearIDPath}/admissions/enrollments/enrolled-students${formattedParams()}`,
    },
    {
      index: 1,
      label: "Unenrolled Students",
      url: `/school/${schoolId}/${schoolYearIDPath}/admissions/enrollments/unenrolled-students${formattedParams()}`,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (value, path) => {
    navigate(path);
  };

  const getIndexFromLocation = () => {
    const index = tabs.findIndex((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    if (index < 0) return tabs[0].index;
    return tabs[index].index;
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    if (getTabFromUrl) handleTabChange(getTabFromUrl.index, getTabFromUrl.url);
    else navigate(tabs[0].url);
  }, []);

  return (
    <Tabs value={getIndexFromLocation()} sx={pageTabs}>
      {tabs?.map((tab) => (
        <Tab
          key={tab.index}
          value={tab.index}
          label={tab.label}
          onClick={() => handleTabChange(tab.index, tab.url)}
        />
      ))}
    </Tabs>
  );
}
