import {
  Container,
  Grid,
  Box,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams, Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { pageHeaderTitle, py30, loader } from "../components/sharedStyles";
import subjectService from "../service/subjectService";
import {
  divider,
  subjectsListTable,
  subjectsListName,
  subjectsListHead,
  subjectsListCell,
  subjectsListCellName,
  tableContainer,
  hidden,
} from "./MySubjects.styles";
import TermSelect from "../components/TermSelect";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import Authorize from "../components/Authorize";

export default function MySubjects() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const schoolId = params.school_id;
  const schoolYearID = params.school_year_id;
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(searchParams.get("direction") || "desc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const fetchAllSubjectsInTerm = async () => {
    setLoading(true);
    const response = await subjectService.fetchAllSubjectsInTerm({
      params: {
        school_id: schoolId,
        term_id: searchParams.get("term"),
        order,
        orderBy,
      },
    });
    if (response.data) {
      setSubjects(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchParams.get("term") !== "select") {
      fetchAllSubjectsInTerm();
      setOrder(searchParams.get("direction") || "desc");
      setOrderBy(searchParams.get("sort") || "name");
    }
  }, [order, orderBy, searchParams.get("term")]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={hidden} />;
  };

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.MY_SUBJECTS)}>
      <Box sx={tableContainer}>
        <Grid container justifyContent="between" alignItems="center">
          <Grid item md={4}>
            <Typography sx={pageHeaderTitle}>Subjects</Typography>
          </Grid>
          <Grid item md={6} />
          <Grid
            item
            md={2}
            container
            alignItems="center"
            sx={{ height: "55px" }}
          >
            <TermSelect
              schoolId={schoolId}
              fetchUpcomingTerms
              findCurrentTerm
              showDefault
              schoolYearID={schoolYearID}
            />
          </Grid>
        </Grid>
        <Divider sx={divider} />
      </Box>
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              {subjects.length > 0 ? (
                <TableContainer sx={py30}>
                  <Table sx={subjectsListTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={subjectsListHead(false, orderBy === "name")}
                          onClick={() => handleRequestSort("name")}
                        >
                          <Typography sx={subjectsListName}>
                            NAME {renderSortChevron("name")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={subjectsListHead(true)}>
                          <Typography>ABBREVIATION</Typography>
                        </TableCell>
                        <TableCell sx={subjectsListHead(false)}>
                          <Typography>DESCRIPTION</Typography>
                        </TableCell>
                        <TableCell sx={subjectsListHead(true)}>
                          <Typography>CLASSES</Typography>
                        </TableCell>
                        <TableCell sx={subjectsListHead} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subjects.map((subject) => (
                        <TableRow hover key={subject.id}>
                          <TableCell sx={subjectsListCellName}>
                            <Link
                              to={`/school/${schoolId}/${schoolYearID}/subjects/${
                                subject.id
                              }/students?term=${searchParams.get("term")}`}
                            >
                              <Typography>
                                <span>{subject.name}</span>
                              </Typography>
                            </Link>
                          </TableCell>
                          <TableCell align="center" sx={subjectsListCell(true)}>
                            <Typography>
                              <span>{subject.abbreviation}</span>
                            </Typography>
                          </TableCell>
                          <TableCell sx={subjectsListCell()}>
                            <Typography>{subject.description}</Typography>
                          </TableCell>
                          <TableCell align="center" sx={subjectsListCell(true)}>
                            <Typography>
                              <span>{subject.klasses_count}</span>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography align="center">No subjects found.</Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Authorize>
  );
}
