import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import reportsService from "../../../service/reportsService";
import ClassStudents from "./KlassStudents";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";

export default function KlassRoster() {
  const [teachers, setTeachers] = useState([]);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [schoolName] = useOutletContext();

  useEffect(() => {
    const termId = searchParams.get("term");
    if (termId)
      (async () => {
        const response = await reportsService.klassRoster({
          school_year_id: params.school_year_id,
          school_id: params.school_id,
          term_id: termId,
        });
        const roster = response.filter(
          (r) =>
            r.klasses.length > 0 &&
            r.klasses.filter((k) => k.students.length > 0).length > 0
        );

        setTeachers(roster);
      })();
  }, [params.school_year_id, searchParams.get("term")]);

  const generateBodyContent = teachers.map((teacher, parentIndex) => (
    <tr className="report-data" key={teacher.id}>
      <td
        className="report-content-cell"
        style={{ padding: "2rem 1rem 0 2rem" }}
      >
        <div>
          <Grid>
            <Grid key={teacher.id} container direction="column" pt={3}>
              <Typography fontSize={24} color="black">
                {teacher.name_title} {teacher.first_name} {teacher.last_name}
              </Typography>
            </Grid>
            {teacher.klasses.map((klass, klassIndex) => (
              <Grid key={klass.id} pt={3}>
                <Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    px="16px"
                  >
                    <Typography fontWeight={700} color="black">
                      {klass.name} ({klass.abbreviation})
                    </Typography>
                    <Typography fontWeight={700} color="black" fontSize={13}>
                      TOTAL STUDENTS: {klass.students.length}
                    </Typography>
                  </Grid>
                  <ClassStudents students={klass.students} />
                </Grid>
                {(klassIndex < teacher.klasses.length - 1 ||
                  parentIndex < teachers.length - 1) && (
                  <div style={{ pageBreakBefore: "always" }} />
                )}
              </Grid>
            ))}
            {parentIndex < teachers.length - 1 && (
              <div style={{ pageBreakBefore: "always" }} />
            )}
          </Grid>
        </div>
      </td>
    </tr>
  ));
  return (
    <PrintLayout
      headerContent="Class Roster"
      bodyContent={generateBodyContent}
      footerContent={schoolName}
    />
  );
}
