import React from "react";
import { Route } from "react-router-dom";
import Notifications from "../views/Notifications";
import List from "../components/Notifications/List";
import Details from "../components/Notifications/Details";

export default [
  <Route
    path="/school/:school_id/:school_year_id/notifications"
    element={<Notifications />}
    key={0}
  >
    <Route index element={<List />} key={1} />

    <Route
      path="/school/:school_id/:school_year_id/notifications/:notification_id/details"
      element={<Details />}
      key={2}
    />
  </Route>,
];
