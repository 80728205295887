import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useSearchParams, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { StudentsTable } from "../components";
import studentsService from "../service/studentsService";
import { pageHeaderTitle, loader } from "../components/sharedStyles";
import TermSelect from "../components/TermSelect";

const CssSearchField = styled(TextField)({
  "& .MuiInput-root": {
    marginRight: "17px",
    "&:before": {
      borderBottom: "1px solid #22282C !important",
    },
  },
});

const studentListContainer = (isCompact) => ({
  px: isCompact && "40px !important",
});

export default function StudentList({ isCompact = false }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = React.useState(
    searchParams.get("direction") || "asc"
  );
  const [orderBy, setOrderBy] = React.useState(
    searchParams.get("sort") || "student"
  );
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(searchParams.get("page") || 1);
  const [query, setQuery] = React.useState(searchParams.get("query") || "");
  const [students, setStudents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(25);
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = React.useState(
    searchParams.get("query") || false
  );
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const schoolId = params.school_id;
  const schoolYearID = params.school_year_id;

  const handleSearch = (data) => {
    searchParams.set("query", data.search);
    setQuery(data.search);
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await studentsService.fetchAllStudents(
        {
          params: {
            school_id: schoolId,
            term_id: searchParams.get("term"),
            school_year_id: schoolYearID,
          },
        },
        page,
        orderBy,
        order,
        query,
        count
      );
      const queryPage = searchParams.get("page") || 1;
      const lastPage = response.pagination.total_pages;
      const currentPage = queryPage > lastPage ? lastPage : queryPage;
      const countPerPage = searchParams.get("count") || 25;
      setStudents(response.data);
      setTotalPages(lastPage);
      setPage(currentPage);
      setCount(countPerPage);
      setLoading(false);
    })();

    setOrder(searchParams.get("direction") || "asc");
    setOrderBy(searchParams.get("sort") || "student");
    setQuery(searchParams.get("query") || "");
  }, [
    page,
    order,
    orderBy,
    query,
    count,
    searchParams.get("term"),
    schoolYearID,
  ]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <div>
      <Container
        maxWidth={false}
        variant="header"
        sx={studentListContainer(isCompact)}
      >
        <Stack
          direction={isCompact ? "column" : "row"}
          alignItems={isCompact ? "baseline" : "center"}
          justifyContent={isCompact ? "start" : "space-between"}
        >
          <Typography sx={pageHeaderTitle}>Students</Typography>

          {!isCompact && (
            <Stack direction="row">
              <form
                onSubmit={handleSubmit(handleSearch)}
                onChange={handleQuickFindChange}
              >
                <CssSearchField
                  label="Quick Find"
                  variant="standard"
                  color="primary"
                  fullWidth={!!isCompact}
                  defaultValue={query}
                  {...register("search")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickOnClearButton}
                          sx={cancelButtonStyle}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                        <IconButton type="submit">
                          <SearchIcon size="small" color="primary" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>

              <TermSelect
                schoolId={params.school_id}
                fetchUpcomingTerms
                findCurrentTerm
                showDefault
                schoolYearID={schoolYearID}
              />
            </Stack>
          )}
        </Stack>
        <StudentsTable
          isCompact={isCompact}
          students={students}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          selected={selected}
          order={order}
          orderBy={orderBy}
          setSelected={setSelected}
          setPage={setPage}
          totalPages={totalPages}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          page={page}
        />
      </Container>
    </div>
  );
}
