import React, { useEffect, useState, useContext, useRef } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Drawer,
  ButtonBase,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import {
  assignmentDetailInner,
  assignmentDetailName,
  assignmentNameContainer,
  assignmentStudentCount,
  assignmentDetailScoreHead,
  assignmentDetailHead,
  assignmentDetailHeadName,
  assignmentDetailDates,
} from "./AssignmentTable.style";
import {
  py10,
  gcLogoContainer,
  cursorPointer,
  mr30,
  rightDrawerContainer,
} from "../../sharedStyles";
import AssignmentRow from "./AssignmentRow";
import assignmentService from "../../../service/assignmentService";
import specialMarkService from "../../../service/specialMarkService";
import gcLogoPath from "../../../utils/constants/googleClassroom";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import AssignmentsForm from "../../Assignments/AssignmentsForm";
import { QuickBarContext } from "../../../context/QuickBarContext";
import googleService from "../../../service/googleService";
import AlertDialog from "../../AlertDialog";
import { SnackbarContext } from "../../../context/SnackbarContext";
import PopoverComponent from "../../ToolTip/ToolTip";

export default function AssignmentTable() {
  const params = useParams();
  const [assignment, setAssignment] = useState(null);
  const [studentsAssigned, setStudentsAssigned] = useState(0);
  const [studentsInKlass, setStudentsInKlass] = useState(0);
  const [assignmentsStudents, setAssignmentsStudents] = useState([]);
  const [assignmentsDrawerStatusEdit, setAssignmentsDrawerStatusEdit] =
    useState(false);
  const assignmentId = params.assignment_id;
  const schoolId = params.school_id;
  const subjectId = params.subject_id;
  const klassId = params.klass_id;
  const schoolYearID = params.school_year_id;
  const [specialMarks, setSpecialMarks] = useState([]);
  const quickbarContext = useContext(QuickBarContext);
  const isGoogleAssignment = !!assignment?.lms_assignment_id;
  const [searchParams] = useSearchParams();
  const [turnedInOnlineDeleteDialog, setTurnedInOnlineDeleteDialog] =
    useState(false);
  const [assignmentStudentId, setAssignmentStudentId] = useState(null);
  const snackbarContext = useContext(SnackbarContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [showSpecialMarks, setShowSpecialMarks] = useState(false);
  const [maxScoreChanged, setMaxScoreChanged] = useState(false);
  const timeout = useRef();

  const handleAssignmentsDrawer = (status) => {
    setAssignmentsDrawerStatusEdit(status);
  };

  const getAssignmentStudents = async () => {
    const response = await assignmentService.fetchAllAssignmentsStudents(
      assignmentId,
      {
        params: {
          school_year_id: schoolYearID,
          school_id: schoolId,
          subject_id: subjectId,
          klass_id: klassId,
        },
      }
    );
    if (response.data) {
      setAssignment(response.data.assignment);
      setAssignmentsStudents(response.data.assignments_students);
      setStudentsAssigned(response.data.students_assigned);
      setStudentsInKlass(response.data.students_in_klass);
    }
  };

  const syncAssignments = async () => {
    const response = await googleService.sync({
      params: { subjectId },
    });

    if (response.data === "Synced") {
      quickbarContext.cleanSyncAssignments();
      getAssignmentStudents();
    }

    if (response.data === "Error") {
      quickbarContext.cleanSyncAssignments();
    }
  };

  useEffect(() => {
    if (quickbarContext.syncAssignments === true) {
      syncAssignments();
    }
  }, [quickbarContext.syncAssignments]);

  useEffect(() => {
    (async () => {
      const response = await specialMarkService.fetchSchoolSpecialMarks({
        params: {
          school_id: schoolId,
        },
      });
      if (response.data) {
        setSpecialMarks(response.data.special_marks);
      }
    })();
  }, [assignmentId]);

  useEffect(() => {
    if (isGoogleAssignment) {
      quickbarContext.fireGoogleAssignments();
    } else {
      quickbarContext.cleanGoogleAssignments();
    }

    getAssignmentStudents();
  }, [
    assignmentsDrawerStatusEdit,
    isGoogleAssignment,
    assignmentId,
    maxScoreChanged,
  ]);

  const gradingInput = assignment?.grading;

  const getMarkingCodes = (assignmentStudent) => {
    const markingCodes =
      assignmentStudent?.assignment?.klass?.grading_scale?.marking_codes;

    if (!markingCodes) {
      return [];
    }

    const isPassFail = assignmentStudent?.assignment?.klass?.is_pass_fail;
    const isABC = markingCodes.some(
      (item) => item.code === "A" || item.code === "B"
    );

    if (isPassFail) {
      return markingCodes.filter(({ code }) => code === "P" || code === "F");
    }
    if (isABC) {
      return markingCodes.filter(({ code }) => code !== "P");
    }

    return markingCodes;
  };

  const handleTurnedInOnlineCloseDialog = () => {
    setTurnedInOnlineDeleteDialog(false);
  };

  const deleteTurnedInOnline = async () => {
    assignmentService
      .deleteTurnedInOnline(assignmentId, assignmentStudentId)
      .then(() => {
        setTurnedInOnlineDeleteDialog(false);
        snackbarContext.setSnackbar({
          message: "Turned in assignment deleted.",
          severity: "success",
          open: true,
        });
        getAssignmentStudents();
      })
      .catch(() => {
        setTurnedInOnlineDeleteDialog(false);
        snackbarContext.setSnackbar({
          message: "Something went wrong.",
          severity: "error",
          open: true,
        });
      });
  };
  const updateStudentsAssignmentsScore = async (studentsAssignmentsScores) => {
    clearTimeout(timeout.current);
    snackbarContext.setSnackbar({
      message: "Saving...",
      severity: "info",
      open: true,
    });

    return new Promise((resolve, reject) => {
      timeout.current = setTimeout(async () => {
        try {
          const postScores =
            await assignmentService.updateMultipleStudentAssignment({
              students_scores: studentsAssignmentsScores,
              term_id: searchParams.get("term"),
            });
          snackbarContext.setSnackbar({
            message: "Saved",
            severity: "success",
            open: true,
          });
          resolve(postScores);
        } catch (error) {
          snackbarContext.setSnackbar({
            message: "Error, try again later.",
            severity: "error",
            open: true,
          });
          reject(error);
        }
      }, 2000);
    });
  };

  const handleScoreClick = (target) => {
    setCurrentTarget(target);
    setAnchorEl(target);
  };

  const optimisticUpdateAssignments = (newScore) =>
    assignmentsStudents.map((student) => {
      const newStudent = JSON.parse(JSON.stringify(student));

      // Check if the score is empty and update it
      if (newStudent.score === "" || newStudent.score === null) {
        newStudent.score = newScore.toUpperCase();
      }

      return newStudent;
    });

  const handleScoreChange = async (_student, _assignment, value) => {
    setTimeout(() => {
      setAnchorEl(null);
    });

    // Update the grades for the UI before server response
    const updatedAssignments = optimisticUpdateAssignments(value);
    setAssignmentsStudents(updatedAssignments);

    const inputs = document.getElementsByClassName("assignment_score_input");
    const studentScoreArray = [];
    for (let i = 0; i < inputs.length; i += 1) {
      if (!inputs[i].value) {
        inputs[i].value = value;
        const studentId = inputs[i].id;
        studentScoreArray.push({
          student_id: studentId,
          subject_id: subjectId,
          assignment_id: _assignment.id,
          score: value.toUpperCase(),
        });
      }
    }

    try {
      await updateStudentsAssignmentsScore(studentScoreArray);
    } catch (error) {
      snackbarContext.setSnackbar({
        message: "Error updating or refreshing scores",
        severity: "error",
        open: true,
      });
      console.error(error);
    }
  };

  const handleMaxScoreChange = async (_assignment, maxScore) => {
    setTimeout(() => {
      setAnchorEl(null);
    });
    const updateMaxScore = await assignmentService
      .updateMaxScore({
        assignmentId: _assignment.id,
        max_score: maxScore || _assignment.max_score,
      })
      .catch(() =>
        snackbarContext.setSnackbar({
          message: "Error, try again later.",
          severity: "error",
          open: true,
        })
      );
    if (updateMaxScore) {
      snackbarContext.setSnackbar({
        message: "Saved",
        severity: "success",
        open: true,
      });
      setMaxScoreChanged(true);
    }
  };

  return (
    <>
      <Stack direction="row" sx={assignmentDetailInner}>
        <Stack direction="row" sx={assignmentNameContainer}>
          <Typography sx={assignmentDetailName}>{assignment?.name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="start">
          <Typography sx={assignmentStudentCount}>
            {assignment?.klass.abbreviation} / {assignment?.category} /{" "}
            {studentsAssigned} of {studentsInKlass} students assigned
          </Typography>
          {isGoogleAssignment && (
            <Box sx={mr30}>
              <Box
                sx={gcLogoContainer}
                onClick={() =>
                  window.open(assignment.gc_assignment_link, "_blank")
                }
              >
                <img src={gcLogoPath} alt="logo" />
              </Box>
            </Box>
          )}
          <Box sx={cursorPointer} onClick={() => handleAssignmentsDrawer(true)}>
            <SettingsOutlinedIcon />
          </Box>
        </Stack>
      </Stack>

      <Stack direction="row" sx={assignmentDetailDates}>
        <Typography sx={mr30}>
          {`Assigned:
          ${moment(assignment?.assigned_date).format(dateTimeFormats.MMDDYYYY)}
          ${assignment?.assigned_time || ""}`}
        </Typography>
        {assignment?.due_date && (
          <Typography>
            {`Due:
          ${moment(assignment?.due_date).format(dateTimeFormats.MMDDYYYY)}
          ${assignment?.due_time || ""}`}
          </Typography>
        )}
      </Stack>

      <Container maxWidth="lg">
        <TableContainer sx={py10}>
          <Table>
            <TableHead>
              <TableRow>
                {isGoogleAssignment && (
                  <TableCell sx={assignmentDetailHeadName} />
                )}
                {assignment?.turn_in_online && (
                  <TableCell sx={assignmentDetailHeadName} />
                )}
                <TableCell sx={assignmentDetailHeadName} align="left">
                  <Typography>
                    <span>NAME</span>
                  </Typography>
                </TableCell>
                {isGoogleAssignment && (
                  <TableCell align="center" sx={assignmentDetailHead}>
                    <Typography>
                      <span>GOOGLE</span>
                    </Typography>
                  </TableCell>
                )}
                {gradingInput === "points" && (
                  <TableCell sx={assignmentDetailHead} align="center">
                    <Typography>MAX POINTS</Typography>
                  </TableCell>
                )}
                <TableCell align="center" sx={assignmentDetailScoreHead}>
                  <ButtonBase
                    onClick={(e) => handleScoreClick(e.currentTarget)}
                  >
                    <Typography>
                      <span>
                        {gradingInput === "letter_grade"
                          ? "LETTER GRADE"
                          : "SCORE"}
                      </span>
                    </Typography>
                  </ButtonBase>
                </TableCell>
                <TableCell sx={assignmentDetailHead} align="center">
                  <Typography>
                    <span>GRADE</span>
                  </Typography>
                </TableCell>
                <TableCell sx={assignmentDetailHead} />
              </TableRow>
            </TableHead>
            <TableBody>
              {assignmentsStudents?.map((assignmentStudent, index) => (
                <AssignmentRow
                  key={assignmentStudent.id}
                  assignmentStudent={assignmentStudent}
                  index={index}
                  specialMarks={specialMarks}
                  gradingInput={gradingInput}
                  isGoogleAssignment={isGoogleAssignment}
                  markingCodes={getMarkingCodes(assignmentStudent)}
                  setTurnedInOnlineDeleteDialog={setTurnedInOnlineDeleteDialog}
                  setAssignmentStudentId={setAssignmentStudentId}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Drawer anchor="right" open={assignmentsDrawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          {assignment && (
            <AssignmentsForm
              schoolId={schoolId}
              handleAssignmentsDrawer={handleAssignmentsDrawer}
              activeAssignment={assignment}
              assignmentStudentsFromAssignment={assignmentsStudents}
              termId={searchParams.get("term")}
            />
          )}
        </Box>
      </Drawer>

      <PopoverComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showSpecialMarks={showSpecialMarks}
        setShowSpecialMarks={setShowSpecialMarks}
        handleClick={handleScoreChange}
        handleMaxScoreChange={handleMaxScoreChange}
        currentAssignment={assignment}
        currentTarget={currentTarget}
        specialMarks={specialMarks}
        markingCodes={getMarkingCodes({ assignment })}
        gradingInput={gradingInput}
        scores={assignmentsStudents.map((student) => student.score)}
      />

      <AlertDialog
        isOpen={turnedInOnlineDeleteDialog}
        handleClose={handleTurnedInOnlineCloseDialog}
        handleConfirm={deleteTurnedInOnline}
      />
    </>
  );
}
