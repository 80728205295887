import React, { useContext, useRef, useState } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Stack,
  Box,
  TextField,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import {
  gTAssignmentSquare,
  gTAssignmentCell,
  gTTableCellsName,
  gTClassWorkCell,
  gTClassWorkCellContainer,
  gTAssignmentSquareGray,
  gTTableRow,
  gTGradeStack,
  gTTableKlassName,
  gTTableStickyCell,
  gTTableInput,
  emptyAssignmentCell,
} from "./GradebookTable.styles";
import { font20 } from "../../sharedStyles";
import assignmentStudentService from "../../../service/assignmentStudentService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import PopoverComponent from "../../ToolTip/ToolTip";
import ValidInputCodes from "../../ToolTip/ValidInputCodes";
import PartialValidCodes from "../../ToolTip/PartialValidCodes";
import useGrade from "../../../hooks/useGrade";
import useFormattedMarkingCodes from "../../../hooks/useFormattedMarkingCodes";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function GradebookRow({
  student,
  assignmentDates,
  grades,
  categories,
  categoryScore,
  totalScores,
  setGrades,
  setCategoryScores,
  setTotalScores,
  loading,
  setLoading,
  currentCell,
  setCurrentCell,
  specialMarks,
  getInputsRefMap,
  noCategoryKey,
  markingCodes,
  index,
  numberOfRows,
  schoolYears,
}) {
  const [searchParams] = useSearchParams();
  const subjectId = useParams().subject_id;
  const schoolYearID = useParams().school_year_id;
  const currentSchoolYear = schoolYears.find(
    (year) => year.id.toString() === schoolYearID
  );
  const isGray = (id) => grades[id];
  const klassesIds = searchParams.get("classes") || null;
  const timeout = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showSpecialMarks, setShowSpecialMarks] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [isBackspace, setBackspace] = useState(false);

  const formattedMarkingCodes = useFormattedMarkingCodes(markingCodes);
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.KLASSES_GRADEBOOK,
    PERMISSION_TYPES.MANAGE
  );

  const shouldShowToolTip = (value, isLetterGrade, validCode, isNumber) => {
    if (
      !isLetterGrade &&
      !isNumber &&
      !validCode.filter((a) => a === value.toLowerCase()).length > 0
    ) {
      return true;
    }

    if (
      isLetterGrade &&
      (!validCode.filter((a) => a === value.toLowerCase()).length > 0 ||
        isNumber)
    ) {
      return true;
    }

    return false;
  };

  const handleGradeChange = async (
    studentUpdate,
    assignmentUpdate,
    value,
    target
  ) => {
    setCurrentAssignment(assignmentUpdate);
    setCurrentTarget(target);
    const key = `${studentUpdate.id}-${assignmentUpdate.id}-${studentUpdate.klass.id}`;
    const categoryKey = grades[key].assignment.category
      ? `${student.id}-${student.klass.id}-${grades[key].assignment.category}`
      : `${student.id}-${student.klass.id}-${noCategoryKey}`;
    const totalKey = `${student.id}-${student.klass.id}`;
    setCurrentCell(key);
    const gradeInput = assignmentUpdate.grading;
    const isNumber = /^(\d+\.?\d?)$/.test(value);
    const isLetterGrade = gradeInput === "letter_grade";
    const partialCode = PartialValidCodes(
      formattedMarkingCodes,
      specialMarks,
      isLetterGrade
    );
    const validCode = ValidInputCodes(
      formattedMarkingCodes,
      specialMarks,
      isLetterGrade
    ).concat(partialCode, "\\");

    if (
      !isBackspace &&
      shouldShowToolTip(value, isLetterGrade, validCode, isNumber)
    ) {
      setAnchorEl(target);
      return;
    }

    // Clear the previous timeout.
    clearTimeout(timeout.current);
    snackbarContext.setSnackbar({
      message: "Saving grades...",
      severity: "info",
      open: true,
    });
    setLoading(true);

    if (grades[key].assignment.max_score >= value || validCode) {
      setGrades({
        ...grades,
        [key]: {
          ...grades[key],
          score: value === "\\" ? "✓" : value.toUpperCase(),
        },
      });
    }

    timeout.current = setTimeout(async () => {
      const isInSpecialMarks =
        specialMarks.filter((e) => e.code.toLowerCase() === value.toLowerCase())
          .length > 0;
      const enteredPartialCode = partialCode.includes(value.toLowerCase());
      if (
        enteredPartialCode ||
        (!isLetterGrade &&
          !isNumber &&
          !isInSpecialMarks &&
          value !== "" &&
          !validCode.filter((a) => a === value.toLowerCase()).length > 0)
      ) {
        setGrades({
          ...grades,
          [key]: {
            ...grades[key],
            score: "",
          },
        });
        setAnchorEl(target);
      } else {
        const postGrade = await assignmentStudentService
          .updateAndCalculateScores({
            assignment_id: grades[key].assignment.id,
            date: searchParams.get("date"),
            klasses_ids: klassesIds?.split(",").map((k) => Number(k)),
            subject_id: subjectId,
            student_id: studentUpdate.id,
            score: value === "\\" ? "✓" : value.toUpperCase(),
          })
          .catch((err) =>
            snackbarContext.setSnackbar({
              message: `Value ${err.response.data.errors.score[0]}`,
              severity: "error",
              open: true,
            })
          );

        if (postGrade) {
          const grade = postGrade.data.data;
          setLoading(false);

          if (shouldShowToolTip) {
            setCurrentCell("");
          }

          snackbarContext.setSnackbar({
            message: "Grades saved successfully",
            severity: "success",
            open: true,
          });

          const categoryScoresKey =
            grades[key].assignment.category === null
              ? `[${student.id}, ${student.klass.id}, "${noCategoryKey}"]`
              : `[${student.id}, ${student.klass.id}, "${grades[key].assignment.category}"]`;

          setCategoryScores({
            ...categoryScore,
            [categoryKey]:
              grade.category_scores[categoryScoresKey]?.category_score,
          });

          setTotalScores({
            ...totalScores,
            [totalKey]: grade.total_scores[totalKey],
          });
        }
      }
    }, 2000);
  };

  const letterGrade = useGrade(
    totalScores[`${student.id}-${student.klass.id}`],
    100,
    markingCodes,
    specialMarks
  );

  const totalScoresValue = totalScores[`${student.id}-${student.klass.id}`];

  let numberOfColumns = 0;

  assignmentDates.forEach((item) => {
    numberOfColumns += Object.keys(item.assignments).length;
  });

  let numberOfDays = 0;

  assignmentDates.forEach(() => {
    numberOfDays += 1;
  });

  const searchNextFocusableTextFieldColumn = (dateId, rowId, columnId) => {
    if (columnId > numberOfColumns || dateId > numberOfDays) {
      return null;
    }

    const selector = `[data-row="gradebook-row-${dateId}-${rowId}-${columnId}"]`;

    // Try to find the next input field
    const nextInput = document.querySelector(selector);

    // If no input field is found in the current column
    if (nextInput === null) {
      const nextColumnInput = searchNextFocusableTextFieldColumn(
        dateId,
        0,
        columnId + 1
      );

      // If a focusable input field is found in the next column, return it
      if (nextColumnInput !== null && !nextColumnInput.disabled) {
        return nextColumnInput;
      }

      // Try to find an input field in the next day, starting from the first column
      const nextDayInput = searchNextFocusableTextFieldColumn(dateId + 1, 0, 0);

      if (nextDayInput == null) {
        return null;
      }

      if (!nextDayInput.disabled) {
        return nextDayInput;
      }
      // If no focusable input field is found in the next row, return null
      return null;
    }

    // If the found input field is disabled
    if (nextInput.disabled) {
      // Try to find the next input field in the next row same column
      return searchNextFocusableTextFieldColumn(dateId, rowId + 1, columnId);
    }

    // If a focusable input field is found, return it
    return nextInput;
  };

  const searchNextFocusableTextFieldRow = (dateId, rowId, columnId) => {
    if (dateId > numberOfDays || rowId > numberOfRows) {
      return null;
    }

    const selector = `[data-row="gradebook-row-${dateId}-${rowId}-${columnId}"]`;

    // Try to find the next input field
    const nextInput = document.querySelector(selector);

    // If no input field is found in the current day
    if (nextInput === null) {
      // Try to find an input field in the next day, starting from the first column
      const nextDayInput = searchNextFocusableTextFieldRow(
        dateId + 1,
        rowId,
        0
      );
      // If a focusable input field is found in the next day, return it
      if (nextDayInput !== null && !nextDayInput.disabled) {
        return nextDayInput;
      }

      // Try to find an input field in the next row, starting from the first day and column
      const nextRowInput = searchNextFocusableTextFieldRow(0, rowId + 1, 0);

      if (nextRowInput == null) {
        return null;
      }

      if (!nextRowInput.disabled) {
        return nextRowInput;
      }

      // If no focusable input field is found in the next row, return null
      return null;
    }

    // If the found input field is disabled
    if (nextInput.disabled) {
      // Try to find the next input field in the same row and day
      return searchNextFocusableTextFieldRow(dateId, rowId, columnId + 1);
    }

    // If a focusable input field is found, return it
    return nextInput;
  };

  const searchPreviousFocusableTextFieldRow = (dateId, rowId, columnId) => {
    if (rowId <= -1 || dateId <= -1 || columnId <= -1) {
      return null;
    }

    const previousInput = document.querySelector(
      `[data-row="gradebook-row-${dateId}-${rowId}-${columnId}"]`
    );

    // If no input field is found in the current column
    if (previousInput === null || previousInput.disabled) {
      const previousColumnInput = searchPreviousFocusableTextFieldRow(
        dateId,
        rowId,
        columnId - 1
      );

      // If no input field is found in the current day
      if (previousColumnInput !== null && !previousColumnInput.disabled) {
        return previousColumnInput;
      }

      const previousDayInput = searchPreviousFocusableTextFieldRow(
        dateId - 1,
        rowId,
        numberOfColumns
      );

      // If no input field is found in the current row
      if (previousDayInput !== null && !previousDayInput.disabled) {
        return previousDayInput;
      }

      const previousRowInput = searchPreviousFocusableTextFieldRow(
        numberOfDays,
        rowId - 1,
        numberOfColumns
      );

      if (previousRowInput !== null && !previousRowInput.disabled) {
        return previousRowInput;
      }
    }

    // If a focusable input field is found, return it
    return previousInput;
  };

  const searchPreviousFocusableTextFieldColumn = (dateId, rowId, columnId) => {
    if (rowId <= -1 || dateId <= -1 || columnId <= -1) {
      return null;
    }

    const previousInput = document.querySelector(
      `[data-row="gradebook-row-${dateId}-${rowId}-${columnId}"]`
    );

    // If no input field is found in the current row
    if (previousInput === null || previousInput.disabled) {
      const previousColumnInput = searchPreviousFocusableTextFieldColumn(
        dateId,
        rowId - 1,
        columnId
      );

      if (previousColumnInput !== null && !previousColumnInput.disabled) {
        return previousColumnInput;
      }

      const previousDayInput = searchPreviousFocusableTextFieldColumn(
        dateId,
        numberOfRows,
        columnId - 1
      );

      if (previousDayInput !== null && !previousDayInput.disabled) {
        return previousDayInput;
      }

      const previousRowInput = searchPreviousFocusableTextFieldColumn(
        dateId - 1,
        numberOfRows,
        numberOfColumns
      );

      if (previousRowInput !== null && !previousRowInput.disabled) {
        return previousRowInput;
      }
    }

    // If a focusable input field is found, return it
    return previousInput;
  };

  const handleKeyDown = (event) => {
    const { key, shiftKey, target: currentElement } = event;

    if (key === "Backspace") {
      setBackspace(true);
    }

    let nextInput = null;

    if (key === "Tab" || key === "Enter") {
      event.preventDefault();

      const dateId = parseInt(
        currentElement.getAttribute("data-row").split("-")[2],
        10
      );
      const rowId = parseInt(
        currentElement.getAttribute("data-row").split("-")[3],
        10
      );
      const columnId = parseInt(
        currentElement.getAttribute("data-row").split("-")[4],
        10
      );

      // TAB
      if (key === "Tab") {
        if (shiftKey) {
          // Search previous field same row
          nextInput = searchPreviousFocusableTextFieldRow(
            dateId,
            rowId,
            columnId - 1
          );

          if (nextInput === null) {
            // Search previous row
            nextInput = searchPreviousFocusableTextFieldRow(
              dateId - 1,
              rowId,
              numberOfColumns
            );
          }

          if (nextInput === null) {
            // Search next row
            nextInput = searchPreviousFocusableTextFieldRow(
              numberOfDays,
              rowId - 1,
              numberOfColumns
            );
          }
        } else {
          // Search same row
          nextInput = searchNextFocusableTextFieldRow(
            dateId,
            rowId,
            columnId + 1
          );

          // Search same row other day
          if (nextInput === null) {
            // Search next day
            nextInput = searchNextFocusableTextFieldRow(dateId + 1, rowId, 0);
          }

          if (nextInput === null) {
            // Search next row
            nextInput = searchNextFocusableTextFieldRow(0, rowId + 1, 0);
          }
        }

        if (nextInput !== null) {
          nextInput.focus();
        }

        return;
      }

      // ENTER
      if (shiftKey) {
        // Search previous row
        nextInput = searchPreviousFocusableTextFieldColumn(
          dateId,
          rowId - 1,
          columnId
        );

        if (nextInput === null) {
          // Search previous column
          nextInput = searchPreviousFocusableTextFieldColumn(
            dateId,
            numberOfRows,
            columnId - 1
          );
        }

        if (nextInput === null) {
          // Search previous day
          nextInput = searchPreviousFocusableTextFieldColumn(
            dateId - 1,
            numberOfRows,
            numberOfColumns
          );
        }
      } else {
        // Search same column
        nextInput = searchNextFocusableTextFieldColumn(
          dateId,
          rowId + 1,
          columnId
        );

        if (nextInput === null) {
          // Search next column
          nextInput = searchNextFocusableTextFieldColumn(
            dateId,
            0,
            columnId + 1
          );
        }
      }

      if (nextInput !== null) {
        nextInput.focus();
      }
    }
  };

  return (
    <TableRow sx={gTTableRow}>
      <TableCell sx={gTTableStickyCell}>
        <TableCell sx={gTTableCellsName}>
          <Typography sx={font20}>
            {`${student.last_name}, ${student.first_name} ${
              student.middle_name ? `${student.middle_name.charAt(0)}. ` : ""
            }`}
          </Typography>
        </TableCell>
        <TableCell sx={gTTableKlassName} align="center">
          <Typography>{student.klass.abbreviation}</Typography>
        </TableCell>
      </TableCell>
      {assignmentDates.length > 0 ? (
        assignmentDates.map((date, dateIndex) => (
          <TableCell key={date.assignedDate} sx={gTAssignmentCell}>
            <Stack direction="row" sx={gTGradeStack}>
              {Object.values(date.assignments).map(
                (assignment, columnIndex) => {
                  const gradeValue =
                    grades[
                      `${student.id}-${assignment.id}-${student.klass.id}`
                    ];
                  const isCellValid = isGray(
                    `${student.id}-${assignment.id}-${student.klass.id}`
                  );
                  return (
                    <TextField
                      inputRef={(node) => {
                        if (node && isCellValid) {
                          const map = getInputsRefMap();
                          map.set(
                            `${student.id}-${assignment.id}-${assignment.klass.id}`,
                            node
                          );
                        }
                      }}
                      variant="standard"
                      key={`${student.id}-${assignment.id}-${student.klass.id}`}
                      sx={
                        isCellValid
                          ? gTAssignmentSquare
                          : gTAssignmentSquareGray
                      }
                      id={`field-${student.id}-${assignment.id}-${student.klass.id}`}
                      fullWidth
                      disabled={
                        currentSchoolYear.gradebook_locked ||
                        !managePermission ||
                        (currentCell !== "" &&
                          currentCell !==
                            `${student.id}-${assignment.id}-${student.klass.id}` &&
                          loading) ||
                        !(
                          `${student.id}-${assignment.id}-${student.klass.id}` in
                          grades
                        )
                      }
                      inputProps={{
                        min: 0,
                        sx: {
                          ...gTTableInput,
                        },
                        id: student.id,
                        className: `assignment_grade_input_${assignment.id}`,
                        "data-row": `gradebook-row-${dateIndex}-${index}-${columnIndex}`,
                      }}
                      /* eslint-disable-next-line react/jsx-no-duplicate-props */
                      InputProps={{
                        sx: {
                          ...gTTableInput,
                        },
                        disableUnderline: true,
                      }}
                      value={
                        gradeValue && gradeValue.score ? gradeValue.score : ""
                      }
                      onChange={(e) =>
                        handleGradeChange(
                          student,
                          assignment,
                          e.target.value,
                          e.currentTarget
                        )
                      }
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                      }}
                      onKeyUp={() => setBackspace(false)}
                    />
                  );
                }
              )}
            </Stack>
            <PopoverComponent
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              showSpecialMarks={showSpecialMarks}
              setShowSpecialMarks={setShowSpecialMarks}
              handleClick={handleGradeChange}
              student={student}
              currentAssignment={currentAssignment}
              currentTarget={currentTarget}
              specialMarks={specialMarks}
              markingCodes={markingCodes}
            />
          </TableCell>
        ))
      ) : (
        <TableCell sx={emptyAssignmentCell} />
      )}
      {categories.map((category) => {
        const categoryScoreValue =
          categoryScore[`${student.id}-${student.klass.id}-${category}`];
        return (
          <TableCell
            key={`${student.id}-${student.klass.id}-${category}`}
            sx={gTClassWorkCell}
          >
            <Box sx={gTClassWorkCellContainer}>
              {categoryScoreValue && `${categoryScoreValue}%`}
            </Box>
          </TableCell>
        );
      })}
      <TableCell sx={gTClassWorkCell}>
        <Box sx={gTClassWorkCellContainer}>
          <Box>{letterGrade}</Box>
          {totalScoresValue && `${totalScoresValue}%`}
        </Box>
      </TableCell>
    </TableRow>
  );
}
