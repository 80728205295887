import React, { useContext, useState } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Stack,
  Box,
  TextField,
} from "@mui/material";

import { useParams } from "react-router-dom";
import {
  gTAssignmentSquare,
  gTAssignmentCell,
  gTTableCellsName,
  gTClassWorkCell,
  gTClassWorkCellContainer,
  gTAssignmentSquareGray,
  gTTableRow,
  gTGradeStack,
  gTTableKlassName,
  gTTableStickyCell,
  gTTableInput,
  emptyAssignmentCell,
} from "./NewGradebookTable.styles";
import { font11, font14 } from "../../sharedStyles";
import PopoverComponent from "../../ToolTip/ToolTip";
import ValidInputCodes from "../../ToolTip/ValidInputCodes";
import PartialValidCodes from "../../ToolTip/PartialValidCodes";
import useGrade from "../../../hooks/useGrade";
import useFormattedMarkingCodes from "../../../hooks/useFormattedMarkingCodes";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { useUnsavedChanges } from "../../../context/UnsavedChangesContext";

export default function NewGradebookRow({
  student,
  assignmentDates,
  grades,
  categories,
  categoryScore,
  totalScores,
  setGrades,
  specialMarks,
  getInputsRefMap,
  markingCodes,
  index,
  numberOfRows,
  setSaveChangesOpen,
  schoolYears,
}) {
  const isGray = (id) => grades[id];
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSpecialMarks, setShowSpecialMarks] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [isBackspace, setBackspace] = useState(false);
  const formattedMarkingCodes = useFormattedMarkingCodes(markingCodes);
  const { hasPermission } = useContext(PermissionsContext);
  const { setUnsavedChanges } = useUnsavedChanges();
  const schoolYearID = useParams().school_year_id;
  const currentSchoolYear = schoolYears.find(
    (year) => year.id.toString() === schoolYearID
  );

  const managePermission = hasPermission(
    APP_PERMISSIONS.KLASSES_GRADEBOOK,
    PERMISSION_TYPES.MANAGE
  );

  const shouldShowToolTip = (value, isLetterGrade, validCode, isNumber) => {
    if (
      !isLetterGrade &&
      !isNumber &&
      !validCode.filter((a) => a === value.toLowerCase()).length > 0
    ) {
      return true;
    }

    if (
      isLetterGrade &&
      (!validCode.filter((a) => a === value.toLowerCase()).length > 0 ||
        isNumber)
    ) {
      return true;
    }

    return false;
  };

  const handleGradeChange = async (
    studentUpdate,
    assignmentUpdate,
    value,
    target
  ) => {
    setCurrentAssignment(assignmentUpdate);
    setCurrentTarget(target);
    const key = `${studentUpdate.id}-${assignmentUpdate.id}-${studentUpdate.klass.id}`;
    const gradeInput = assignmentUpdate.grading;
    const isNumber = /^(\d+\.?\d?)$/.test(value);
    const isLetterGrade = gradeInput === "letter_grade";
    const partialCode = PartialValidCodes(
      formattedMarkingCodes,
      specialMarks,
      isLetterGrade
    );
    const validCode = ValidInputCodes(
      formattedMarkingCodes,
      specialMarks,
      isLetterGrade
    ).concat(partialCode, "\\");

    if (
      !isBackspace &&
      shouldShowToolTip(value, isLetterGrade, validCode, isNumber)
    ) {
      setAnchorEl(target);
      return;
    }

    if (grades[key].assignment.max_score >= value || validCode) {
      setGrades({
        ...grades,
        [key]: {
          ...grades[key],
          score: value === "\\" ? "✓" : value.toUpperCase(),
        },
      });
    }

    setSaveChangesOpen(true);
    setUnsavedChanges(true);
  };

  const letterGrade = useGrade(
    totalScores[`${student.id}-${student.klass.id}`],
    100,
    markingCodes,
    specialMarks
  );

  const totalScoresValue = totalScores[`${student.id}-${student.klass.id}`];

  let numberOfColumns = 0;

  assignmentDates.forEach((item) => {
    numberOfColumns += Object.keys(item.assignments).length;
  });

  let numberOfDays = 0;

  assignmentDates.forEach(() => {
    numberOfDays += 1;
  });

  const searchNextFocusableTextFieldColumn = (dateId, rowId, columnId) => {
    if (columnId > numberOfColumns || dateId > numberOfDays) {
      return null;
    }

    const selector = `[data-row="gradebook-row-${dateId}-${rowId}-${columnId}"]`;

    // Try to find the next input field
    const nextInput = document.querySelector(selector);

    // If no input field is found in the current column
    if (nextInput === null) {
      const nextColumnInput = searchNextFocusableTextFieldColumn(
        dateId,
        0,
        columnId + 1
      );

      // If a focusable input field is found in the next column, return it
      if (nextColumnInput !== null && !nextColumnInput.disabled) {
        return nextColumnInput;
      }

      // Try to find an input field in the next day, starting from the first column
      const nextDayInput = searchNextFocusableTextFieldColumn(dateId + 1, 0, 0);

      if (nextDayInput == null) {
        return null;
      }

      if (!nextDayInput.disabled) {
        return nextDayInput;
      }
      // If no focusable input field is found in the next row, return null
      return null;
    }

    // If the found input field is disabled
    if (nextInput.disabled) {
      // Try to find the next input field in the next row same column
      return searchNextFocusableTextFieldColumn(dateId, rowId + 1, columnId);
    }

    // If a focusable input field is found, return it
    return nextInput;
  };

  const searchNextFocusableTextFieldRow = (dateId, rowId, columnId) => {
    if (dateId > numberOfDays || rowId > numberOfRows) {
      return null;
    }

    const selector = `[data-row="gradebook-row-${dateId}-${rowId}-${columnId}"]`;

    // Try to find the next input field
    const nextInput = document.querySelector(selector);

    // If no input field is found in the current day
    if (nextInput === null) {
      // Try to find an input field in the next day, starting from the first column
      const nextDayInput = searchNextFocusableTextFieldRow(
        dateId + 1,
        rowId,
        0
      );
      // If a focusable input field is found in the next day, return it
      if (nextDayInput !== null && !nextDayInput.disabled) {
        return nextDayInput;
      }

      // Try to find an input field in the next row, starting from the first day and column
      const nextRowInput = searchNextFocusableTextFieldRow(0, rowId + 1, 0);

      if (nextRowInput == null) {
        return null;
      }

      if (!nextRowInput.disabled) {
        return nextRowInput;
      }

      // If no focusable input field is found in the next row, return null
      return null;
    }

    // If the found input field is disabled
    if (nextInput.disabled) {
      // Try to find the next input field in the same row and day
      return searchNextFocusableTextFieldRow(dateId, rowId, columnId + 1);
    }

    // If a focusable input field is found, return it
    return nextInput;
  };

  const searchPreviousFocusableTextFieldRow = (dateId, rowId, columnId) => {
    if (rowId <= -1 || dateId <= -1 || columnId <= -1) {
      return null;
    }

    const previousInput = document.querySelector(
      `[data-row="gradebook-row-${dateId}-${rowId}-${columnId}"]`
    );

    // If no input field is found in the current column
    if (previousInput === null || previousInput.disabled) {
      const previousColumnInput = searchPreviousFocusableTextFieldRow(
        dateId,
        rowId,
        columnId - 1
      );

      // If no input field is found in the current day
      if (previousColumnInput !== null && !previousColumnInput.disabled) {
        return previousColumnInput;
      }

      const previousDayInput = searchPreviousFocusableTextFieldRow(
        dateId - 1,
        rowId,
        numberOfColumns
      );

      // If no input field is found in the current row
      if (previousDayInput !== null && !previousDayInput.disabled) {
        return previousDayInput;
      }

      const previousRowInput = searchPreviousFocusableTextFieldRow(
        numberOfDays,
        rowId - 1,
        numberOfColumns
      );

      if (previousRowInput !== null && !previousRowInput.disabled) {
        return previousRowInput;
      }
    }

    // If a focusable input field is found, return it
    return previousInput;
  };

  const searchPreviousFocusableTextFieldColumn = (dateId, rowId, columnId) => {
    if (rowId <= -1 || dateId <= -1 || columnId <= -1) {
      return null;
    }

    const previousInput = document.querySelector(
      `[data-row="gradebook-row-${dateId}-${rowId}-${columnId}"]`
    );

    // If no input field is found in the current row
    if (previousInput === null || previousInput.disabled) {
      const previousColumnInput = searchPreviousFocusableTextFieldColumn(
        dateId,
        rowId - 1,
        columnId
      );

      if (previousColumnInput !== null && !previousColumnInput.disabled) {
        return previousColumnInput;
      }

      const previousDayInput = searchPreviousFocusableTextFieldColumn(
        dateId,
        numberOfRows,
        columnId - 1
      );

      if (previousDayInput !== null && !previousDayInput.disabled) {
        return previousDayInput;
      }

      const previousRowInput = searchPreviousFocusableTextFieldColumn(
        dateId - 1,
        numberOfRows,
        numberOfColumns
      );

      if (previousRowInput !== null && !previousRowInput.disabled) {
        return previousRowInput;
      }
    }

    // If a focusable input field is found, return it
    return previousInput;
  };

  const handleKeyDown = (event) => {
    const { key, shiftKey, target: currentElement } = event;

    if (key === "Backspace") {
      setBackspace(true);
    }

    let nextInput = null;

    if (key === "Tab" || key === "Enter") {
      event.preventDefault();

      const dateId = parseInt(
        currentElement.getAttribute("data-row").split("-")[2],
        10
      );
      const rowId = parseInt(
        currentElement.getAttribute("data-row").split("-")[3],
        10
      );
      const columnId = parseInt(
        currentElement.getAttribute("data-row").split("-")[4],
        10
      );

      // TAB
      if (key === "Tab") {
        if (shiftKey) {
          // Search previous field same row
          nextInput = searchPreviousFocusableTextFieldRow(
            dateId,
            rowId,
            columnId - 1
          );

          if (nextInput === null) {
            // Search previous row
            nextInput = searchPreviousFocusableTextFieldRow(
              dateId - 1,
              rowId,
              numberOfColumns
            );
          }

          if (nextInput === null) {
            // Search next row
            nextInput = searchPreviousFocusableTextFieldRow(
              numberOfDays,
              rowId - 1,
              numberOfColumns
            );
          }
        } else {
          // Search same row
          nextInput = searchNextFocusableTextFieldRow(
            dateId,
            rowId,
            columnId + 1
          );

          // Search same row other day
          if (nextInput === null) {
            // Search next day
            nextInput = searchNextFocusableTextFieldRow(dateId + 1, rowId, 0);
          }

          if (nextInput === null) {
            // Search next row
            nextInput = searchNextFocusableTextFieldRow(0, rowId + 1, 0);
          }
        }

        if (nextInput !== null) {
          nextInput.focus();
        }

        return;
      }

      // ENTER
      if (shiftKey) {
        // Search previous row
        nextInput = searchPreviousFocusableTextFieldColumn(
          dateId,
          rowId - 1,
          columnId
        );

        if (nextInput === null) {
          // Search previous column
          nextInput = searchPreviousFocusableTextFieldColumn(
            dateId,
            numberOfRows,
            columnId - 1
          );
        }

        if (nextInput === null) {
          // Search previous day
          nextInput = searchPreviousFocusableTextFieldColumn(
            dateId - 1,
            numberOfRows,
            numberOfColumns
          );
        }
      } else {
        // Search same column
        nextInput = searchNextFocusableTextFieldColumn(
          dateId,
          rowId + 1,
          columnId
        );

        if (nextInput === null) {
          // Search next column
          nextInput = searchNextFocusableTextFieldColumn(
            dateId,
            0,
            columnId + 1
          );
        }
      }

      if (nextInput !== null) {
        nextInput.focus();
      }
    }
  };

  return (
    <TableRow sx={gTTableRow}>
      <TableCell sx={gTTableStickyCell}>
        <TableCell sx={gTTableCellsName}>
          <Typography sx={font14}>
            {`${student.last_name}, ${student.first_name} ${
              student.middle_name ? `${student.middle_name.charAt(0)}. ` : ""
            }`}
          </Typography>
        </TableCell>
        <TableCell sx={gTTableKlassName} align="center">
          <Typography sx={font11}>{student.klass.abbreviation}</Typography>
        </TableCell>
      </TableCell>
      {assignmentDates.length > 0 ? (
        assignmentDates.map((date, dateIndex) => (
          <TableCell key={date.assignedDate} sx={gTAssignmentCell}>
            <Stack direction="row" sx={gTGradeStack}>
              {Object.values(date.assignments).map(
                (assignment, columnIndex) => {
                  const gradeValue =
                    grades[
                      `${student.id}-${assignment.id}-${student.klass.id}`
                    ];
                  const isCellValid = isGray(
                    `${student.id}-${assignment.id}-${student.klass.id}`
                  );
                  return isCellValid ? (
                    <TextField
                      inputRef={(node) => {
                        if (node) {
                          const map = getInputsRefMap();
                          map.set(
                            `${student.id}-${assignment.id}-${assignment.klass.id}`,
                            node
                          );
                        }
                      }}
                      variant="standard"
                      key={`${student.id}-${assignment.id}-${student.klass.id}`}
                      sx={gTAssignmentSquare}
                      id={`field-${student.id}-${assignment.id}-${student.klass.id}`}
                      fullWidth
                      disabled={
                        currentSchoolYear &&
                        (currentSchoolYear.gradebook_locked ||
                          !managePermission ||
                          !(
                            `${student.id}-${assignment.id}-${student.klass.id}` in
                            grades
                          ))
                      }
                      inputProps={{
                        min: 0,
                        sx: {
                          ...gTTableInput,
                        },
                        id: student.id,
                        className: `assignment_grade_input_${assignment.id}`,
                        "data-row": `gradebook-row-${dateIndex}-${index}-${columnIndex}`,
                      }}
                      /* eslint-disable-next-line react/jsx-no-duplicate-props */
                      InputProps={{
                        sx: {
                          ...gTTableInput,
                        },
                        disableUnderline: true,
                      }}
                      value={
                        gradeValue && gradeValue.score ? gradeValue.score : ""
                      }
                      onChange={(e) =>
                        handleGradeChange(
                          student,
                          assignment,
                          e.target.value,
                          e.currentTarget
                        )
                      }
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                      }}
                      onKeyUp={() => setBackspace(false)}
                    />
                  ) : (
                    <TextField
                      variant="standard"
                      key={`${student.id}-${assignment.id}-${student.klass.id}`}
                      sx={gTAssignmentSquareGray}
                      disabled
                    />
                  );
                }
              )}
            </Stack>
          </TableCell>
        ))
      ) : (
        <TableCell sx={emptyAssignmentCell} />
      )}
      {categories.map((category) => {
        const score =
          categoryScore instanceof Map
            ? categoryScore
            : new Map(Object.entries(categoryScore));
        const categoryScoreValue = score?.get(
          `${student.id}-${student.klass.id}-${category}`
        );
        return (
          <TableCell
            key={`${student.id}-${student.klass.id}-${category}`}
            sx={gTClassWorkCell}
          >
            <Box sx={gTClassWorkCellContainer}>
              {categoryScoreValue && `${categoryScoreValue}%`}
            </Box>
          </TableCell>
        );
      })}
      <TableCell sx={gTClassWorkCell}>
        <Box sx={gTClassWorkCellContainer}>
          <Box>{letterGrade}</Box>
          {totalScoresValue && `${totalScoresValue}%`}
        </Box>
      </TableCell>
      <PopoverComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showSpecialMarks={showSpecialMarks}
        setShowSpecialMarks={setShowSpecialMarks}
        handleClick={handleGradeChange}
        student={student}
        currentAssignment={currentAssignment}
        currentTarget={currentTarget}
        specialMarks={specialMarks}
        markingCodes={markingCodes}
      />
    </TableRow>
  );
}
