import React, { useState, useEffect, useContext } from "react";
import { Tab, Tabs } from "@mui/material";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { pageTabs } from "../../sharedStyles";
import { NavigationContext } from "../../../context/NavigationContext";

export default function SetupTabs() {
  const navigationContext = useContext(NavigationContext);
  const params = useParams();
  const schoolId = params.school_id;
  const schoolYearId = params.school_year_id;
  const [searchParams] = useSearchParams();

  const formattedParams = () => {
    let paramsUrl = "?";
    if (searchParams.get("school_year")) {
      paramsUrl = paramsUrl.concat(
        `&school_year=${searchParams.get("school_year")}`
      );
    }

    return paramsUrl;
  };

  const tabs = [
    // {
    //   index: 2,
    //   label: "Application",
    //   url: `/school/${schoolId}/admissions/setup/application${formattedParams()}`,
    // },
    // {
    //   index: 3,
    //   label: "Registrations",
    //   url: `/school/${schoolId}/admissions/setup/registrations${formattedParams()}`,
    // },
    {
      index: 4,
      label: "Processes",
      url: `/school/${schoolId}/${schoolYearId}/admissions/setup/processes${formattedParams()}`,
    },
    {
      index: 5,
      label: "Forms",
      url: `/school/${schoolId}/${schoolYearId}/admissions/setup/forms${formattedParams()}`,
    },
    {
      index: 6,
      label: "Welcome Message",
      url: `/school/${schoolId}/${schoolYearId}/admissions/setup/welcome-message${formattedParams()}`,
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigateTo = (path) => {
    navigationContext.navigateTo(path);
  };

  const handleTabChange = (value, path) => {
    setTabIndex(value);
    navigateTo(path);
  };

  const findAndSetTab = () => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    )?.index;
    if (getTabFromUrl) setTabIndex(getTabFromUrl);
    else navigateTo(tabs[0].url);
  };

  useEffect(() => {
    if (!location.pathname.includes("form-builder")) {
      if (location.pathname.includes("process-builder")) {
        setTabIndex(4);
      } else {
        findAndSetTab();
      }
    }
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("form-builder")) {
      if (location.pathname.includes("process-builder")) {
        setTabIndex(4);
      } else {
        findAndSetTab();
      }
    }
  }, [location]);

  return (
    <Tabs value={tabIndex} sx={pageTabs}>
      {tabs?.map((tab) => (
        <Tab
          key={tab.index}
          value={tab.index}
          label={tab.label}
          onClick={() => handleTabChange(tab.index, tab.url)}
        />
      ))}
    </Tabs>
  );
}
