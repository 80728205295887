import React from "react";
import { Route } from "react-router-dom";
import KlassSelect from "../components/Klass/GoogleClassroom/KlassSelect";

export default [
  <Route
    path="/school/:school_id/:school_year_id/klasses/:klass_id/class-select"
    element={<KlassSelect />}
    key={0}
  />,
];
