import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import {
  reportCardContainer,
  reportCardHeaderContainer,
} from "./ReportCard.styles";
import ReportCardTabs from "./ReportCardTabs";
import { pageHeaderTitle } from "../sharedStyles";
import TermSelect from "./TermSelect";
import termService from "../../service/termService";

export default function ReportCard() {
  const [searchParams] = useSearchParams();
  const [terms, setTerms] = useState([]);
  const schoolId = useParams().school_id;
  const schoolYearID = useParams().school_year_id;

  // Filters
  const [selectedStudentGradeFilter, setSelectedStudentGradeFilter] = useState(
    searchParams.get("gradeLevel") || ""
  );
  const [teacherFilterActive, setTeacherFilterActive] = useState(
    searchParams.get("teacherFilterActive") === "true"
  );
  const [selectedTeacherFilter, setSelectedTeacherFilter] = useState(
    searchParams.get("teacher") || ""
  );
  const [statusFilterActive, setStatusFilterActive] = useState(
    searchParams.get("statusFilterActive") === "true"
  );
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(
    searchParams.get("status") || ""
  );

  const [selectedTerm, setSelectedTerm] = useState(null);

  const cleanFilters = () => {
    setSelectedStudentGradeFilter("");
    setTeacherFilterActive(false);
    setSelectedTeacherFilter("");
    setStatusFilterActive(false);
    setSelectedStatusFilter("");
  };

  const fetchAllTerms = async () => {
    const response = await termService.fetchAllTermsInSchool({
      params: {
        school_year_id: schoolYearID,
        school_id: schoolId,
      },
    });

    if (response.data.terms) {
      setTerms(response.data.terms);
      setSelectedTerm(
        searchParams.get("term") && searchParams.get("term") !== "select"
          ? response.data.terms.find(
              (term) => term.id && term.id === Number(searchParams.get("term"))
            )
          : null
      );
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAllTerms();
    })();
  }, [schoolYearID]);

  return (
    <Container maxWidth={false} variant="header">
      <Box sx={reportCardHeaderContainer}>
        <Typography sx={pageHeaderTitle}>Report Cards</Typography>
        <TermSelect
          schoolId={schoolId}
          terms={terms}
          setSelectedTerm={setSelectedTerm}
          selectedTerm={selectedTerm}
          cleanFilters={cleanFilters}
        />
      </Box>
      <Container maxWidth="xl">
        <ReportCardTabs schoolId={schoolId} />

        <Box sx={reportCardContainer}>
          <Outlet
            context={[
              schoolId,
              selectedTerm,
              selectedStudentGradeFilter,
              setSelectedStudentGradeFilter,
              setSelectedTeacherFilter,
              teacherFilterActive,
              setTeacherFilterActive,
              selectedTeacherFilter,
              statusFilterActive,
              setStatusFilterActive,
              selectedStatusFilter,
              setSelectedStatusFilter,
            ]}
          />
        </Box>
      </Container>
    </Container>
  );
}
