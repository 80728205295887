import React, { useContext } from "react";
import { Container, Grid } from "@mui/material";
import GradebookSettings from "./GradebookSettings";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";
import Authorize from "../Authorize";
import SchoolYearSettings from "./SchoolYearSettings";

export default function ApplicationSettings() {
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.SETTINGS,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.SETTINGS)}>
      <Container>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <SchoolYearSettings />
          </Grid>
          <Grid item xs={6}>
            <GradebookSettings managePermission={managePermission} />
          </Grid>
        </Grid>
      </Container>
    </Authorize>
  );
}
