import React, { useState, useEffect, useContext } from "react";
import { Tab, Tabs } from "@mui/material";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { pageTabs } from "../sharedStyles";
import { PermissionsContext } from "../../context/PermissionsContext";
import { NavigationContext } from "../../context/NavigationContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";

export default function AdmissionsTabs({
  setTabChanged,
  useAdvanceAdmissions,
}) {
  const navigationContext = useContext(NavigationContext);

  const params = useParams();
  const schoolYearID = params.school_year_id;
  const [searchParams] = useSearchParams();
  const { hasAnyPermissionType } = useContext(PermissionsContext);
  const studentListPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_LIST
  );
  const schoolId = params.school_id;

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    return paramsUrl;
  };

  const advancedTabs = [
    ...(studentListPermission
      ? [
          {
            index: 0,
            label: "Students",
            url: `/school/${schoolId}/${schoolYearID}/admissions/students/inquiries${formattedParams()}`,
          },
        ]
      : []),
    {
      index: 1,
      label: "Setup",
      url: `/school/${schoolId}/${schoolYearID}/admissions/setup${formattedParams()}`,
    },
    {
      index: 2,
      label: "Dashboard",
      url: `/school/${schoolId}/${schoolYearID}/admissions/dashboard${formattedParams()}`,
    },
    {
      index: 3,
      label: "Enrollments",
      url: `/school/${schoolId}/${schoolYearID}/admissions/enrollments${formattedParams()}`,
    },
  ];

  const simpleTabs = [
    ...(studentListPermission
      ? [
          {
            index: 0,
            label: "Enrolled Students",
            url: `/school/${schoolId}/${schoolYearID}/admissions/enrollments/enrolled-students${formattedParams()}`,
          },
        ]
      : []),
    ...(studentListPermission
      ? [
          {
            index: 1,
            label: "Unenrolled Students",
            url: `/school/${schoolId}/${schoolYearID}/admissions/enrollments/unenrolled-students${formattedParams()}`,
          },
        ]
      : []),
    {
      index: 2,
      label: "Registrations",
      url: `/school/${schoolId}/${schoolYearID}/admissions/registrations${formattedParams()}`,
    },
  ];

  const tabs = useAdvanceAdmissions ? advancedTabs : simpleTabs;

  const [tabValue, setTabValue] = useState(tabs[0]);
  const location = useLocation();
  const navigateTo = (path) => {
    navigationContext.navigateTo(path);
  };

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);
    setTabChanged(true);
    navigateTo(path);
  };

  useEffect(() => {
    if (
      location.pathname.includes("approvals") ||
      location.pathname.includes("actions")
    )
      setTabValue(null);
    if (location.pathname.includes("student-details")) return;
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    if (getTabFromUrl) setTabValue(getTabFromUrl);
    // else navigateTo(tabs[0].url);
  }, [location]);

  return (
    <Tabs value={tabValue?.index} sx={pageTabs}>
      {tabs?.map((tab) => (
        <Tab
          key={tab.index}
          value={tab.index}
          label={tab.label}
          onClick={() => handleTabChange(tab.index, tab.url)}
        />
      ))}
    </Tabs>
  );
}
