import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  Outlet,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Box,
  Typography,
  Container,
  Tabs,
  Tab,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  messagesTitle,
  messagesTopContainer,
  messagesSearchContainer,
} from "./Messages.styles";
import { pageTabs, pageTabsContainer, mb55 } from "../components/sharedStyles";
import { parameterizeString } from "../utils/constants/utils";

export default function Messages() {
  const [, , teacher, , , , , , , messageDrawerStatus] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query") || null);
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query") || false
  );
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const schoolId = params.school_id;
  const schoolYearId = params.school_year_id;
  const tabs = [
    {
      index: 0,
      label: "Inbox",
      tabId: "inbox",
      url: `/school/${schoolId}/${schoolYearId}/messages/inbox`,
    },
    {
      index: 1,
      label: "Sent Messages",
      tabId: "sent",
      url: `/school/${schoolId}/${schoolYearId}/messages/sent`,
    },
  ];

  const [tabValue, setTabValue] = useState(tabs[0]);

  const handleSearch = (data) => {
    searchParams.set("query", data.search.toLowerCase());
    setQuery(data.search.toLowerCase());
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);

    navigate(path);
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      parameterizeString(location.pathname).includes(
        parameterizeString(tab.tabId)
      )
    );

    setShowClearButton(searchParams.get("query") || false);
    setQuery(searchParams.get("query") || "");
    setTabValue(getTabFromUrl || tabs[0]);
  }, []);

  return (
    <Container maxWidth={false} variant="header">
      <Box>
        <Typography sx={messagesTitle}>Messages</Typography>
      </Box>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={messagesTopContainer}
      >
        <Box sx={pageTabsContainer}>
          <Tabs value={tabValue.index} sx={pageTabs}>
            {tabs.map((tab) => (
              <Tab
                key={tab.index}
                label={tab.label}
                onClick={() => handleTabChange(tab.index, tab.url)}
              />
            ))}
          </Tabs>
        </Box>

        {!location.pathname.includes("/conversation") && (
          <Box sx={messagesSearchContainer}>
            <form
              onSubmit={handleSubmit(handleSearch)}
              onChange={handleQuickFindChange}
            >
              <TextField
                label="Search messages..."
                variant="standard"
                color="primary"
                defaultValue={searchParams.get("query")}
                {...register("search")}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickOnClearButton}
                        sx={cancelButtonStyle}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                      <IconButton type="submit">
                        <SearchIcon size="small" color="primary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Box>
        )}
      </Stack>

      <Box sx={mb55}>
        <Outlet context={{ query, teacher, messageDrawerStatus }} />
      </Box>
    </Container>
  );
}
