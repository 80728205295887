import { Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { pageTabs } from "../components/sharedStyles";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";

export default function ScheduleTabs({ schoolYearId }) {
  const params = useParams();
  const schoolId = params.school_id;
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const termsPermission = hasAnyPermissionType(APP_PERMISSIONS.TERMS);

  const klassSchedulePermission = hasAnyPermissionType(
    APP_PERMISSIONS.KLASS_SCHEDULES
  );

  const schoolDayPermission = hasAnyPermissionType(APP_PERMISSIONS.SCHOOL_DAYS);

  const dayTemplatesPermission = hasAnyPermissionType(
    APP_PERMISSIONS.DAY_TEMPLATE
  );

  const formattedParams = () => {
    let paramsUrl = "?";
    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    return paramsUrl;
  };

  const tabs = [
    ...(termsPermission
      ? [
          {
            label: "Terms",
            url: `/school/${schoolId}/${schoolYearId}/schedules/terms${formattedParams()}`,
          },
        ]
      : []),
    ...(schoolDayPermission
      ? [
          {
            label: "School Days",
            url: `/school/${schoolId}/${schoolYearId}/schedules/school-days${formattedParams()}`,
          },
        ]
      : []),
    ...(dayTemplatesPermission
      ? [
          {
            label: "Day Templates",
            url: `/school/${schoolId}/${schoolYearId}/schedules/day-templates${formattedParams()}`,
          },
        ]
      : []),
    ...(klassSchedulePermission
      ? [
          {
            label: "Class Schedules",
            url: `/school/${schoolId}/${schoolYearId}/schedules/class-schedules${formattedParams()}`,
          },
        ]
      : []),
  ].map((tab, index) => ({ ...tab, index }));

  const [tabValue, setTabValue] = useState(tabs[3]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);

    navigate(path);
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    setTabValue(getTabFromUrl || tabs[1]);
  }, []);

  return (
    <Tabs value={tabValue?.index} sx={pageTabs}>
      {tabs.length > 0 &&
        tabs?.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            onClick={() => handleTabChange(tab.index, tab.url)}
          />
        ))}
    </Tabs>
  );
}
