import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import GenericSubmitButton from "../../GenericSubmitButton";
import { formButtons, formHeader, loader } from "../../sharedStyles";
import { Form } from "../Setup/FormBuilder/Models/Form";
import admissionFormService from "../../../service/admissionFormService";
import FormRenderer from "../Setup/FormRenderer/FormRenderer";
import { FormBuilderContext } from "../Setup/FormBuilder/FormBuilderContext";
import admissionProcessService from "../../../service/admissionProcessService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { QuickBarContext } from "../../../context/QuickBarContext";
import { containerStyle } from "../Setup/FormBuilder/FormBuilder.style";
import { CommonContext } from "../../../context/CommonContext";
import { sortSectionsAndFields } from "../Setup/FormBuilder/FormBuilderUtils";

export default function AddNewInquiry({ setAddNewInquiryDrawerOpen }) {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [processes, setProcesses] = useState([]);
  const { updateForm, form } = useContext(FormBuilderContext);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const submitButtonRef = useRef();
  const snackbarContext = useContext(SnackbarContext);
  const quickBarContext = useContext(QuickBarContext);
  const commonContext = useContext(CommonContext);
  const navigate = useNavigate();
  const schoolId = useParams().school_id;
  const schoolYearID = useParams().school_year_id;

  const handleCancel = () => {
    setAddNewInquiryDrawerOpen(false);
  };

  const formattedParams = () => {
    let paramsUrl = "?";
    if (searchParams.get("school_year")) {
      paramsUrl = paramsUrl.concat(
        `&school_year=${searchParams.get("school_year")}`
      );
    }

    return paramsUrl;
  };

  const navigateToInProcess = () => {
    handleCancel();
    navigate(
      `/school/${schoolId}/${schoolYearID}/admissions/students/in-process${formattedParams()}`
    );
  };

  const onSubmit = () => {
    submitButtonRef.current.click();
  };

  const onFormSaved = (formResponseId) => {
    if (selectedProcess)
      admissionProcessService
        .createProcessInstance(
          formResponseId,
          selectedProcess,
          searchParams.get("school_year")
        )
        .then(() => {
          navigateToInProcess();
          snackbarContext.setSnackbar({
            message: "Process started.",
            severity: "success",
            open: true,
          });
          commonContext.fireProcessStarted();
        })
        .catch(() => {
          snackbarContext.setSnackbar({
            message: "Failed to start process.",
            severity: "error",
            open: true,
          });
        });
    else {
      quickBarContext.fireFetchInquiries();
      handleCancel();
    }
  };

  useEffect(() => {
    if (searchParams.get("school_year")) {
      setLoading(true);
      (async () => {
        const response = await admissionFormService.getInquiryForm(
          searchParams.get("school_year")
        );
        response.data.admission_form_pages[0].form_sections =
          sortSectionsAndFields(
            response.data.admission_form_pages[0].form_sections
          );
        const formObj = new Form(response.data);
        updateForm(formObj);
        setLoading(false);
      })();
      admissionProcessService
        .fetchAllProcesses(searchParams.get("school_year"))
        .then((response) => {
          setProcesses(response.data);
        });
    }
  }, [searchParams.get("school_year")]);

  const renderForm = () => (
    <Grid container p={3} justifyContent="center">
      <Grid item md={12}>
        <FormRenderer
          formObject={form}
          submitButtonRef={submitButtonRef}
          onSaved={onFormSaved}
          selectedProcess={selectedProcess}
          inquiryFormView
        />
      </Grid>
      <Grid item xs={12} display="grid" container sx={containerStyle(true)}>
        <Grid item md={5}>
          <Box pr={2} pl={1}>
            <FormControl fullWidth>
              <InputLabel
                id="processLabel"
                shrink
                sx={{ backgroundColor: "white" }}
              >
                Process
              </InputLabel>
              <Select
                labelId="processLabel"
                id="process"
                label="Process"
                defaultValue=""
                onChange={(e) => {
                  setSelectedProcess(e.target.value);
                }}
              >
                {processes.map((process) => (
                  <MenuItem key={process.id} value={process.id}>
                    <Typography textAlign="left">{process.name}</Typography>
                  </MenuItem>
                ))}
                {!processes.length && (
                  <MenuItem key="select process" disabled>
                    <Typography textAlign="left">
                      Please create process
                    </Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {/* <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{ width: "100%" }}
        > */}
      <Grid
        container
        item
        sx={formHeader}
        direction="row"
        columnSpacing={4}
        rowSpacing={0}
      >
        <Grid item sm={7}>
          <Grid item>
            <Typography variant="h2">Add Inquiry</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-end" sm={5}>
          <Button variant="outlined" sx={formButtons} onClick={handleCancel}>
            Cancel
          </Button>
          <GenericSubmitButton
            onClick={onSubmit}
            text="Save"
            submittingText="Saving..."
          />
        </Grid>
      </Grid>
      {!loading ? renderForm() : <CircularProgress sx={loader} size={100} />}
      {/* </form>
      </FormProvider> */}
    </Grid>
  );
}
