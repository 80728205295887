import {
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  TextField,
  InputAdornment,
  Pagination,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Clear, Search } from "@mui/icons-material";
import admissionProcessService from "../../../service/admissionProcessService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import {
  dashboardContainer,
  leftContainer,
  studentsTableContainer,
  tableStyles,
} from "./dashboard.style";
import { formatDateTime } from "../../../utils/constants/utils";
import ParentMessages from "../Students/ParentMessages";
import { loader, paginationContent } from "../../sharedStyles";

const CssSearchField = styled(TextField)({
  "& .MuiInput-root": {
    marginRight: "17px",
  },
  ".MuiInputBase-root": {
    "&:before": { border: 0 },
    "&:after": { border: 0 },
  },
});

export default function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [allParents] = useState([]);
  const [processInstances, setProcessInstances] = useState([]);
  const snackbarContext = useContext(SnackbarContext);

  const navigate = useNavigate();
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query") || false
  );
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [totalPages, setTotalPages] = useState(1);
  const params = useParams();

  const studentStepMap = {
    in_process: "In Process",
    in_review: "In Review",
    accepted: "Accepted",
    waiting_list: "Waiting List",
    drop: "Drop",
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  const fetchAllProcessInstances = async (searchQuery, pageNumber) =>
    new Promise((resolve, reject) => {
      admissionProcessService
        .fetchAllProcessesInstances(
          searchParams.get("school_year"),
          null,
          searchQuery,
          pageNumber
        )
        .then((response) => {
          setLoading(false);
          resolve(response.data);
          if (response.pagination.total_pages)
            setTotalPages(response.pagination.total_pages);
        })
        .catch((err) => {
          setLoading(false);
          snackbarContext.setSnackbar({
            message: "Failed to fetch students.",
            severity: "error",
            open: true,
          });
          reject(err);
        });
    });

  const resetPage = () => {
    setPage(1);
    searchParams.set("page", 1);
    setSearchParams(searchParams);
  };

  const setProcessInstancesData = async () => {
    const data = await fetchAllProcessInstances(query, page);
    setProcessInstances(data);
  };

  const fetchData = () => {
    setProcessInstancesData();
  };
  useEffect(() => {
    if (searchParams.get("school_year")) {
      resetPage();
      setLoading(true);
      fetchData();
    }
  }, [searchParams.get("school_year")]);

  useEffect(() => {
    setProcessInstancesData();
  }, [query, page]);

  const navigateToProcessOverview = (studentId, processId) => {
    navigate(
      `/school/${params.school_id}/${
        params.school_year_id
      }/admissions/student-details/process-overview?school_year=${searchParams.get(
        "school_year"
      )}&student_id=${studentId}&process_id=${processId}`
    );
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleSearch = (data) => {
    searchParams.set("query", data.search);
    setQuery(data.search);
    setSearchParams(searchParams);
    resetPage();
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  return loading ? (
    <CircularProgress sx={loader} />
  ) : (
    <Grid container mx={4} sx={dashboardContainer}>
      <Grid item md={9.5} sx={leftContainer}>
        {processInstances.length ? (
          <Box>
            <Grid container justifyContent="center">
              <Grid item md={12} sx={studentsTableContainer}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6">Student</Typography>
                  <Stack direction="row">
                    <form
                      onSubmit={handleSubmit(handleSearch)}
                      onChange={handleQuickFindChange}
                    >
                      <CssSearchField
                        label="Quick Find"
                        variant="standard"
                        color="primary"
                        defaultValue={query}
                        {...register("search")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {showClearButton && (
                                <IconButton
                                  onClick={handleClickOnClearButton}
                                  sx={cancelButtonStyle}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              )}
                              {!showClearButton && (
                                <IconButton type="submit">
                                  <Search fontSize="small" color="primary" />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </Stack>
                </Box>
                <TableContainer>
                  <Table sx={tableStyles} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell>NAME</TableCell>
                        <TableCell align="center">GRADE</TableCell>
                        <TableCell>DATE</TableCell>
                        <TableCell>PARENT NAME 1</TableCell>
                        <TableCell>PARENT NAME 2</TableCell>
                        <TableCell align="center">STATUS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {processInstances.map((instance) => (
                        <TableRow key={instance.id}>
                          <TableCell component="th" scope="row">
                            <ButtonBase
                              onClick={() =>
                                navigateToProcessOverview(
                                  instance.student.id,
                                  instance.admission_process.id
                                )
                              }
                            >{`${instance.student.last_name}, ${instance.student.first_name}`}</ButtonBase>
                          </TableCell>
                          <TableCell align="center">
                            {instance.student.admission_grade}
                          </TableCell>
                          <TableCell>
                            {formatDateTime(instance.created_at)}
                          </TableCell>
                          <TableCell>
                            {instance.student.guardians[0]
                              ? `${instance.student.guardians[0].first_name} ${instance.student.guardians[0].last_name}`
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {instance.student.guardians[1]
                              ? `${instance.student.guardians[1].first_name} ${instance.student.guardians[1].last_name}`
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {studentStepMap[instance.student.step]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  onChange={handleChangePage}
                  sx={paginationContent}
                  page={parseInt(page, 10)}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Typography textAlign="center" mt={5}>
            Students not found for the selected year.
          </Typography>
        )}
      </Grid>
      <Grid item md={2.5} py={3.5} pl={1}>
        <Typography>Messages</Typography>
        <ParentMessages parents={allParents} />
      </Grid>
    </Grid>
  );
}
