import {
  Typography,
  Box,
  Tabs,
  Tab,
  ButtonGroup,
  Button,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import {
  enrollmentChecklist,
  enrollmentChecklistStatusLabel,
  enrollmentChecklistTab,
  messageContainer,
  messageContainerTitle,
  processesButtonGroup,
} from "./EnrollmentChecklist.style";
import { pageTabs } from "../../sharedStyles";
import admissionProcessService from "../../../service/admissionProcessService";
import StageElementType from "../../Admissions/Setup/AdmissionProcessBuilder/Models/constants";

export default function EnrollmentChecklist({ student, guardianId }) {
  const [checklistStageInstances, setChecklistStageInstances] = useState([]);
  const [documentStageInstances, setDocumentStageInstances] = useState([]);
  const [processInstancesList, setProcessInstancesList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const formattedParams = () => {
    let paramsUrl = "?";
    const processId = searchParams.get("process_id");
    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }

    return paramsUrl;
  };

  const tabs = [
    {
      index: 0,
      label: "Checklist",
      url: `/guardian/${guardianId}/students/${
        student.id
      }/home/enrollments/checklist${formattedParams()}`,
      value: checklistStageInstances,
    },
    {
      index: 1,
      label: "Documents",
      url: `/guardian/${guardianId}/students/${
        student.id
      }/home/enrollments/documents${formattedParams()}`,
      value: documentStageInstances,
    },
  ];

  const [processDetails, setProcessDetails] = useState(null);
  const [tabValue, setTabValue] = useState(tabs[0]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);
    navigate(path);
  };

  function filterStageInstances(stageInstances, elementTypes) {
    return stageInstances
      .filter((stageInstance) =>
        stageInstance.element_instances.some((elementInstance) =>
          elementTypes.includes(elementInstance.stage_element.element_type)
        )
      )
      .map((stageInstance) => ({
        ...stageInstance,
        element_instances: stageInstance.element_instances.filter(
          (elementInstance) =>
            elementTypes.includes(elementInstance.stage_element.element_type)
        ),
      }));
  }

  const resolveTabsAndUrls = () => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    if (getTabFromUrl) setTabValue(getTabFromUrl);
    else navigate(tabs[0].url);
  };
  useEffect(() => {
    resolveTabsAndUrls();
  }, []);

  const sortStageAndElements = (stages) =>
    stages
      .sort(
        (a, b) =>
          a.admission_process_stage.order - b.admission_process_stage.order
      )
      .map((stage) => {
        // eslint-disable-next-line no-param-reassign
        stage.element_instances = stage.element_instances.sort(
          (a, b) =>
            a.stage_element.properties.order - b.stage_element.properties.order
        );
        return stage;
      });

  const getProcesses = async () => {
    const response =
      await admissionProcessService.fetchAdmissionProcessInstanceDetails(
        student.id,
        null,
        searchParams.get("school_year")
      );
    if (response.data) {
      setProcessInstancesList(response.data);
    }
  };

  useEffect(() => {
    getProcesses();
  }, [student.id, searchParams.get("process_id")]);

  useEffect(() => {
    if (processInstancesList.length > 0) {
      if (searchParams.get("process_id") === null) {
        searchParams.set(
          "process_id",
          processInstancesList.filter((pi) => !pi.hide_from_parent_portal)[0]
            .admission_process_id
        );
        setSearchParams(searchParams);
      }

      const updatedData = processInstancesList
        .filter((pi) => !pi.hide_from_parent_portal)
        .find(
          (process) =>
            process.admission_process_id ===
            parseInt(searchParams.get("process_id"), 10)
        );
      updatedData.stage_instances = sortStageAndElements(
        updatedData.stage_instances
      );
      setProcessInstancesList(processInstancesList);
      setProcessDetails(updatedData);
      searchParams.set("process_id", updatedData.admission_process_id);
      setSearchParams(searchParams);
      setChecklistStageInstances(
        filterStageInstances(updatedData.stage_instances, [
          StageElementType.FORM,
          StageElementType.FEE,
        ])
      );
      setDocumentStageInstances(
        filterStageInstances(updatedData.stage_instances, [
          StageElementType.FILE,
        ])
      );
      setTimeout(() => {
        resolveTabsAndUrls();
      }, 100);
    }
  }, [processInstancesList]);

  const getInstancesForTab = (tabIndex) => {
    if (tabIndex === 1) return documentStageInstances;
    return checklistStageInstances;
  };

  const processStatus = (studentStep) => {
    switch (studentStep) {
      case "inquiry":
        return "Inquiry";
      case "in_process":
        return "In Process";
      case "in_review":
        return "In Review";
      case "waiting_list":
        return "Waiting List";
      case "accepted":
        return "Accepted";
      default:
        return "";
    }
  };

  return (
    processDetails && (
      <Box sx={enrollmentChecklist}>
        <Stack direction="row" justifyContent="right">
          <Typography fontSize={16} fontWeight={500}>
            {processDetails.admission_process.school_year.name}
          </Typography>
          <Typography sx={{ mx: "5px" }}>|</Typography>
          <Typography sx={enrollmentChecklistStatusLabel}>
            {processStatus(student.step)}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Box sx={messageContainer}>
            <Typography sx={messageContainerTitle}>
              Welcome to the{" "}
              {processDetails.admission_process.school_year.school.name}{" "}
              admissions tool
            </Typography>
            <Interweave
              newWindow
              matchers={[new UrlMatcher("url")]}
              content={
                processDetails.admission_process.school_year.welcome_message
              }
            />
          </Box>
          <Box sx={{ width: "60%" }}>
            <ButtonGroup
              variant="contained"
              sx={{ borderRadius: "15px", boxShadow: "none" }}
            >
              {processInstancesList
                .filter((pi) => !pi.hide_from_parent_portal)
                .map((_processInstance, index) => (
                  <Button
                    key={_processInstance.id}
                    sx={processesButtonGroup(
                      _processInstance.admission_process_id ===
                        Number(searchParams.get("process_id")),
                      index === 0,
                      index ===
                        processInstancesList.filter(
                          (pi) => !pi.hide_from_parent_portal
                        ).length -
                          1
                    )}
                    onClick={() => {
                      searchParams.set(
                        "process_id",
                        _processInstance.admission_process_id
                      );
                      setSearchParams(searchParams);
                    }}
                  >
                    {_processInstance.admission_process.name}
                  </Button>
                ))}
            </ButtonGroup>
            <Box>
              <Tabs
                centered
                value={tabValue?.index}
                sx={{
                  ...pageTabs,
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#2196F3",
                  },
                }}
              >
                {tabs?.map((tab) => (
                  <Tab
                    key={tab.index}
                    value={tab.index}
                    label={tab.label}
                    sx={enrollmentChecklistTab}
                    onClick={() => handleTabChange(tab.index, tab.url)}
                  />
                ))}
              </Tabs>

              <Outlet
                context={{
                  stageInstances: getInstancesForTab(tabValue.index),
                }}
              />
            </Box>
          </Box>
        </Stack>
      </Box>
    )
  );
}
