import {
  Box,
  ButtonBase,
  CircularProgress,
  LinearProgress,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";
import { tableStyles, linearProgress } from "./studentWithProcess.style";
import admissionProcessService from "../../../service/admissionProcessService";
import {
  formatDateTime,
  getProgressColor,
} from "../../../utils/constants/utils";
import { black, loader, pt30, textCenter } from "../../sharedStyles";

export default function StudentWithProcessList({ status }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [processInstances, setProcessInstances] = useState([]);
  const [processInstance, setProcessInstance] = useState(null);
  const [anchorElModal, setAnchorElModal] = useState(null);
  const openMenu = Boolean(anchorElModal);
  const params = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (searchParams.get("school_year"))
      (async () => {
        setLoading(true);
        const response =
          await admissionProcessService.fetchAllProcessesInstances(
            searchParams.get("school_year"),
            status
          );
        setLoading(false);
        setProcessInstances(
          response.data.filter((pi) => !pi.hide_from_parent_portal)
        );
      })();
  }, [searchParams.get("school_year")]);

  const formattedParams = () => {
    let paramsUrl = "?";
    if (searchParams.get("school_year")) {
      paramsUrl = paramsUrl.concat(
        `&school_year=${searchParams.get("school_year")}`
      );
    }

    return paramsUrl;
  };

  const navigateToProcessOverview = (studentId, processId) => {
    navigate(
      `/school/${params.school_id}/${
        params.school_year_id
      }/admissions/student-details/process-overview?${formattedParams()}` +
        `&student_id=${studentId}&process_id=${processId}`
    );
  };

  const navigateTopDataView = (formResponseId) => {
    let paramsUrl = formattedParams();
    paramsUrl = paramsUrl.concat(`&form_response_id=${formResponseId}`);
    navigate(`/school/${params.school_id}/data-view${paramsUrl}`);
  };

  const handleModalClick = async (event, pi) => {
    setProcessInstance(pi);
    setAnchorElModal(event.currentTarget);
  };

  const handleModalClose = () => {
    setAnchorElModal(null);
    setProcessInstance(null);
  };

  return loading ? (
    <CircularProgress sx={loader} size={100} />
  ) : (
    (processInstances.length && (
      <>
        <TableContainer>
          <Table sx={tableStyles} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>STUDENT NAME</TableCell>
                <TableCell align="center">GRADE</TableCell>
                <TableCell>SUBSTATUS</TableCell>
                <TableCell>CREATED</TableCell>
                <TableCell>PARENT NAME 1</TableCell>
                <TableCell>PARENT NAME 2</TableCell>
                <TableCell>PROCESS</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {processInstances.map((instance) => (
                <TableRow key={instance.id}>
                  <TableCell component="th" scope="row">
                    <ButtonBase
                      onClick={() =>
                        navigateToProcessOverview(
                          instance.student.id,
                          instance.admission_process.id
                        )
                      }
                    >{`${instance.student.last_name}, ${instance.student.first_name}`}</ButtonBase>
                  </TableCell>
                  <TableCell align="center">
                    {instance.student.admission_grade}
                  </TableCell>
                  <TableCell>Substatus</TableCell>
                  <TableCell>{formatDateTime(instance.created_at)}</TableCell>
                  <TableCell>
                    {instance.student.guardians[0]
                      ? `${instance.student.guardians[0].first_name} ${instance.student.guardians[0].last_name}`
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {instance.student.guardians[1]
                      ? `${instance.student.guardians[1].first_name} ${instance.student.guardians[1].last_name}`
                      : "-"}
                  </TableCell>
                  <TableCell>{instance.admission_process.name}</TableCell>
                  <TableCell>
                    <LinearProgress
                      sx={linearProgress(
                        getProgressColor(instance.completion_percentage)
                      )}
                      variant="determinate"
                      value={instance.completion_percentage}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonBase
                      sx={black}
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={(e) => handleModalClick(e, instance)}
                    >
                      <MoreVert />
                    </ButtonBase>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="long-menu"
          anchorEl={anchorElModal}
          open={openMenu}
          onClose={handleModalClose}
        >
          <MenuItem
            autoFocus={false}
            onClick={() =>
              navigateToProcessOverview(
                processInstance.student.id,
                processInstance.admission_process.id
              )
            }
          >
            Student Dashboard
          </MenuItem>
          {processInstance?.form_response_id && (
            <MenuItem
              autoFocus={false}
              onClick={() =>
                navigateTopDataView(processInstance.form_response_id)
              }
            >
              View inquiry form
            </MenuItem>
          )}
        </Menu>
      </>
    )) || (
      <Box width="100%">
        <Typography sx={[textCenter, pt30]}>No students found.</Typography>
      </Box>
    )
  );
}
