import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import tableStyles from "./approvalsAndActions.style";
import admissionProcessService from "../../../service/admissionProcessService";

export default function Actions() {
  const [searchParams] = useSearchParams();
  const schoolId = useParams().school_id;
  const schoolYearId = useParams().school_year_id;
  const [actions, setActions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("school_year"))
      admissionProcessService
        .pendingActions(searchParams.get("school_year"))
        .then((response) => {
          setActions(response.data);
        });
  }, [searchParams.get("school_year")]);

  const navigateToDashboard = (studentId, processId) => {
    navigate(
      `/school/${schoolId}/${schoolYearId}/admissions/student-details/process-overview??&school_year=${searchParams.get(
        "school_year"
      )}&student_id=${studentId}&process_id=${processId}`
    );
  };
  return (
    <Grid container p={5}>
      <Grid item md={12}>
        <Typography fontSize={24} textAlign="center">
          Actions
        </Typography>
      </Grid>
      {actions.map((action) => (
        <Grid item md={12} container>
          <Grid item md={12}>
            <Typography fontSize={18} color="black">
              {action.student.name}
            </Typography>
          </Grid>
          <TableContainer>
            <Table sx={tableStyles}>
              <TableBody>
                {action.elements.map((element) => (
                  <TableRow>
                    <TableCell>
                      <Typography fontSize={14}>
                        {element.stage_element.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>
                        {element.properties.description || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {element.properties.isReady && (
                        <Button
                          onClick={() =>
                            navigateToDashboard(
                              action.student.id,
                              element.stage_instance.process_instance
                                .admission_process_id
                            )
                          }
                          sx={{
                            textTransform: "none",
                            color: "#2196F3",
                            borderColor: "#2196F3",
                          }}
                          variant="outlined"
                        >
                          Ready For Action
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ))}
    </Grid>
  );
}
