import React from "react";
import { Route } from "react-router-dom";
import Attendance from "../views/Attendance";
import MySubjects from "../views/MySubjects";
import StudentList from "../views/StudentList";
import MyKlasses from "../views/MyKlasses";
import SocialDevelopment from "../views/SocialDevelopment";
import Reports from "../views/Reports";
import Admissions from "../views/Admissions";
import Staffs from "../views/Staffs";
import Assignments from "../views/Assignments";
import PreKDevelopmentTracker from "../views/PreKDevelopmentTracker";

export default [
  <Route
    key={0}
    path="/school/:school_id/:school_year_id/students"
    element={<StudentList />}
  />,
  <Route
    key={1}
    path="/school/:school_id/:school_year_id/attendance"
    element={<Attendance />}
  />,
  <Route
    key={2}
    path="/school/:school_id/:school_year_id/current-subjects"
    element={<MySubjects />}
  />,
  <Route
    key={3}
    path="/school/:school_id/:school_year_id/staff"
    element={<Staffs />}
  />,
  <Route
    key={4}
    path="/school/:school_id/:school_year_id/admissions"
    element={<Admissions />}
  />,
  <Route
    key={5}
    path="/school/:school_id/:school_year_id/current-classes"
    element={<MyKlasses />}
  />,
  <Route
    key={6}
    path="/school/:school_id/:school_year_id/social-development"
    element={<SocialDevelopment />}
  />,
  <Route
    key={7}
    path="/school/:school_id/:school_year_id/reports"
    element={<Reports />}
  />,
  <Route
    key={8}
    path="/school/:school_id/:school_year_id/assignments"
    element={<Assignments />}
  />,
  <Route
    key={9}
    path="/school/:school_id/:school_year_id/pk-development-tracker"
    element={<PreKDevelopmentTracker />}
  />,
];
