import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Drawer,
  Box,
  CircularProgress,
  Link,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  useOutletContext,
  useSearchParams,
  Link as RouterLink,
  useParams,
} from "react-router-dom";
import classScheduleService from "../../../service/classScheduleService";
import { mr10, py30, rightDrawerContainer, loader } from "../../sharedStyles";
import {
  hidden,
  listCell,
  listCellName,
  listHead,
  listName,
  listTable,
  settingsCell,
} from "./ClassSchedules.styles";
import ClassScheduleForm from "./ClassScheduleForm";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { PermissionsContext } from "../../../context/PermissionsContext";
import Authorize from "../../Authorize";

export default function ClassSchedules() {
  const schoolYearId = useParams().school_year_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [drawerStatusEdit, setDrawerStatusEdit] = useState(false);
  const [selectedClassSchedule, setSelectedClassSchedule] = useState(null);
  const [classSchedules, setClassSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [
    selectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    setSelectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    termsDrawerStatus,
    setSnackBarOpen,
    // eslint-disable-next-line no-unused-vars
    dayTemplateDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    schoolDaysDrawerStatus,
    classScheduleDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    klassesDrawerStatus,
  ] = useOutletContext();

  const params = useParams();
  const schoolId = params.school_id;
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.KLASS_SCHEDULES,
    PERMISSION_TYPES.MANAGE
  );

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={hidden} />;
  };

  const getClassSchedules = async () => {
    if (!classScheduleDrawerStatus && !drawerStatusEdit) {
      setLoading(true);
    }

    if (selectedSchoolYear && !drawerStatusEdit) {
      const response = await classScheduleService.fetchAllBySchoolYear({
        params: {
          school_year_id: selectedSchoolYear.id,
          orderBy,
          order,
        },
      });
      if (response.data) {
        setClassSchedules(response.data.klass_schedules);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getClassSchedules();
  }, [
    selectedSchoolYear,
    classScheduleDrawerStatus,
    drawerStatusEdit,
    searchParams.get("direction"),
  ]);

  const handleDrawer = (status, classSchedule) => {
    setDrawerStatusEdit(status);
    if (status === false) {
      setSelectedClassSchedule(null);
    } else {
      setSelectedClassSchedule(classSchedule);
    }
  };

  const formattedParams = () => {
    let paramsUrl = "?";
    if (selectedSchoolYear) {
      paramsUrl = paramsUrl.concat(`&school_year=${selectedSchoolYear.id}`);
    }

    return paramsUrl;
  };

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Authorize
      permission={hasAnyPermissionType(APP_PERMISSIONS.KLASS_SCHEDULES)}
    >
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              {classSchedules.length > 0 ? (
                <TableContainer sx={py30}>
                  <Table sx={listTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={listHead}
                          onClick={() => handleRequestSort("name")}
                        >
                          <Typography sx={listName}>
                            NAME {renderSortChevron("name")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={listHead}>
                          <Typography sx={mr10}>TERM</Typography>
                        </TableCell>
                        <TableCell sx={listHead}>
                          <Typography sx={mr10}>SCHOOL DAYS</Typography>
                        </TableCell>
                        <TableCell sx={listHead}>
                          <Typography sx={mr10}>CLASSES</Typography>
                        </TableCell>
                        <TableCell sx={listHead}>
                          <Typography sx={mr10}>DAY TEMPLATES</Typography>
                        </TableCell>
                        <TableCell sx={listHead}>
                          <Typography sx={mr10}>PUBLISHED</Typography>
                        </TableCell>
                        <TableCell sx={listHead} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {classSchedules.map((classSchedule) => (
                        <TableRow hover key={classSchedule.id}>
                          <TableCell sx={listCellName}>
                            <Link
                              to={`/school/${schoolId}/${schoolYearId}/schedules/class-schedules/${
                                classSchedule.id
                              }/classes${formattedParams()}`}
                              underline="none"
                              component={RouterLink}
                            >
                              <Typography>{classSchedule.name}</Typography>
                            </Link>
                          </TableCell>
                          <TableCell sx={listCell}>
                            <Typography>{classSchedule?.term.name}</Typography>
                          </TableCell>
                          <TableCell sx={listCell}>
                            <Typography>
                              {classSchedule?.school_days_count}
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell}>
                            <Typography>
                              {classSchedule?.klasses_count}
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell}>
                            <Typography>
                              {classSchedule?.day_templates
                                .map((dayTemplate) => dayTemplate.abbreviation)
                                .join(", ")}
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell}>
                            <Typography>
                              {classSchedule?.is_published ? "Yes" : "No"}
                            </Typography>
                          </TableCell>
                          {managePermission && (
                            <TableCell
                              sx={settingsCell}
                              onClick={() => handleDrawer(true, classSchedule)}
                            >
                              <SettingsOutlinedIcon />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography align="center">No schedules found</Typography>
              )}
            </Grid>
          </Grid>
        </Container>
        <Drawer anchor="right" open={drawerStatusEdit}>
          <Box sx={rightDrawerContainer}>
            {selectedClassSchedule ? (
              <ClassScheduleForm
                handleClassScheduleDrawer={handleDrawer}
                classSchedule={selectedClassSchedule}
                setSnackBarOpen={setSnackBarOpen}
                schoolYear={selectedSchoolYear}
              />
            ) : (
              <CircularProgress sx={loader} size={100} />
            )}
          </Box>
        </Drawer>
      </Container>
    </Authorize>
  );
}
