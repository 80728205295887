import React from "react";
import { Route } from "react-router-dom";
import ReportCardTable from "../components/ReportCard/ReportCardTable";
import ReportCard from "../components/ReportCard/ReportCard";
import ReportCardDashboard from "../components/ReportCard/ReportCardDashboard";

export default [
  <Route
    path="/school/:school_id/:school_year_id/report-cards"
    element={<ReportCard />}
    key={0}
  >
    <Route
      key={1}
      path="/school/:school_id/:school_year_id/report-cards/dashboard"
      element={<ReportCardDashboard />}
    />
    ,
    <Route
      key={2}
      path="/school/:school_id/:school_year_id/report-cards/"
      element={<ReportCardTable />}
    />
  </Route>,
];
