import React, { useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { pageTabs } from "../../sharedStyles";

export default function StudentsTabs({ setTabChanged }) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const schoolId = params.school_id;
  const schoolYearId = params.school_year_id;

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    return paramsUrl;
  };

  const tabs = [
    // {
    //   index: 0,
    //   label: "Enrolled Students",
    //   url: `/school/${schoolId}/admissions/students/enrolled-students${formattedParams()}`,
    // },
    // {
    //   index: 1,
    //   label: "Unenrolled Students",
    //   url: `/school/${schoolId}/admissions/students/unenrolled-students${formattedParams()}`,
    // },
    {
      index: 0,
      label: "Inquiries",
      url: `/school/${schoolId}/${schoolYearId}/admissions/students/inquiries${formattedParams()}`,
    },
    {
      index: 1,
      label: "In Process",
      url: `/school/${schoolId}/${schoolYearId}/admissions/students/in-process${formattedParams()}`,
    },
    {
      index: 2,
      label: "In Review",
      url: `/school/${schoolId}/${schoolYearId}/admissions/students/in-review${formattedParams()}`,
    },
    {
      index: 3,
      label: "Waiting List",
      url: `/school/${schoolId}/${schoolYearId}/admissions/students/waiting-list${formattedParams()}`,
    },
    {
      index: 4,
      label: "Accepted",
      url: `/school/${schoolId}/${schoolYearId}/admissions/students/accepted${formattedParams()}`,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (value, path) => {
    setTabChanged(true);
    navigate(path);
  };

  const getIndexFromLocation = () => {
    const index = tabs.findIndex((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    if (index < 0) return tabs[0].index;
    return tabs[index].index;
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    if (getTabFromUrl) handleTabChange(getTabFromUrl.index, getTabFromUrl.url);
    else navigate(tabs[0].url);
  }, []);

  return (
    <Tabs value={getIndexFromLocation()} sx={pageTabs}>
      {tabs?.map((tab) => (
        <Tab
          key={tab.index}
          value={tab.index}
          label={tab.label}
          onClick={() => handleTabChange(tab.index, tab.url)}
        />
      ))}
    </Tabs>
  );
}
