import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { pageTabs, pageTabsContainer } from "../../sharedStyles";
import { parameterizeString } from "../../../utils/constants/utils";

export default function TermGradeTabs({
  schoolId,
  subjectId,
  klassesIds,
  termId,
  schoolYearID,
}) {
  const formattedParams = () => {
    let paramsUrl = "?";
    if (klassesIds) {
      paramsUrl = paramsUrl.concat(`&classes=${klassesIds}`);
    }

    if (termId) {
      paramsUrl = paramsUrl.concat(`&term=${termId}`);
    }
    return paramsUrl;
  };

  const tabs = [
    {
      index: 0,
      label: "Class Grade",
      url: `/school/${schoolId}/${schoolYearID}/subjects/${subjectId}/term-grade/class-grade${formattedParams()}`,
    },
    {
      index: 1,
      label: "Skills",
      url: `/school/${schoolId}/${schoolYearID}/subjects/${subjectId}/term-grade/skills${formattedParams()}`,
    },
  ];
  const [tabValue, setTabValue] = useState(tabs[0]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);

    navigate(path);
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      parameterizeString(location.pathname).includes(
        parameterizeString(tab.label)
      )
    );

    setTabValue(getTabFromUrl || tabs[0]);
  }, [subjectId, location]);
  return (
    <Box sx={pageTabsContainer}>
      <Tabs value={tabValue.index} sx={pageTabs}>
        {tabs.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            onClick={() => handleTabChange(tab.index, tab.url)}
          />
        ))}
      </Tabs>
    </Box>
  );
}
