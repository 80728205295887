import React, { useContext } from "react";
import { Container } from "@mui/material";
import StudentPortfolioCollections from "./StudentPortfolioCollections/StudentPorfolioCollections";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";
import Authorize from "../Authorize";
import SchoolDaysSettings from "./SchoolDaysSettings/SchoolDaysSettings";
import WithdrawalReasons from "./WidrawalReasons/WithdrawalReasons";
import Departments from "./Departments/Departments";

export default function Settings() {
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.SETTINGS,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.SETTINGS)}>
      <Container>
        <StudentPortfolioCollections managePermission={managePermission} />
        <SchoolDaysSettings managePermission={managePermission} />
        <WithdrawalReasons managePermission={managePermission} />
        <Departments managePermission={managePermission} />
      </Container>
    </Authorize>
  );
}
