import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Radio,
  Checkbox,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { font28, my30, font18 } from "../sharedStyles";
import SnackBarNotification from "../SnackBarNotification";
import { boldFont } from "../StudentInfo/Messages/StudentInfoMessages.styles";
import schoolYearService from "../../service/schoolYearService";

export default function SchoolYearSettings() {
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const params = useParams();
  const schoolId = params.school_id;
  const [schoolYears, setSchoolYears] = useState([]);

  const getSchoolYears = async () => {
    const response = await schoolYearService.fetchAll({
      params: {
        school_id: schoolId,
      },
    });
    if (response.data) {
      setSchoolYears(response.data.school_years);
    }
  };

  useEffect(() => {
    getSchoolYears();
  }, []);

  const handleDefaultViewChange = async (yearId) => {
    try {
      const result = await schoolYearService.updatePermissions({
        id: yearId,
        is_default_view: true,
      });

      if (result.data) {
        setSchoolYears((prevYears) =>
          prevYears.map((year) => ({
            ...year,
            is_default_view: year.id === yearId,
          }))
        );
      } else {
        console.error("Error updating default view");
      }
    } catch (error) {
      console.error("Error updating default view:", error);
    }
  };

  const handlePermissionChange = async (yearId, permission, value) => {
    try {
      const result = await schoolYearService.updatePermissions({
        id: yearId,
        [permission]: value,
      });

      if (result.data) {
        setSchoolYears((prevYears) =>
          prevYears.map((year) =>
            year.id === yearId ? { ...year, [permission]: value } : year
          )
        );
      } else {
        console.error("Error updating permission");
      }
    } catch (error) {
      console.error("Error updating permission:", error);
    }
  };

  return (
    <Box sx={{ ...my30, justifyContent: "center" }}>
      <Typography sx={font28}>School Year</Typography>
      <Grid container spacing={2} mt={2}>
        <Grid container item xs={12}>
          <Typography sx={font18} color="#000000">
            <Box component="span" sx={boldFont}>
              IMPORTANT
            </Box>
            -Unlocking the gradebook and report cards of previous years allows
            users with appropiate permissions to edit and post grades, as well
            as modify and publish report cards.
          </Typography>
        </Grid>
        <Grid container item xs={12} mt={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>DEFAULT VIEW</TableCell>
                <TableCell>GRADEBOOK</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolYears.map((year) => (
                <TableRow key={year.id}>
                  <TableCell>{year.name}</TableCell>
                  <TableCell align="center">
                    <Radio
                      checked={year.is_default_view}
                      onChange={() => handleDefaultViewChange(year.id)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={year.gradebook_locked}
                      onChange={(e) =>
                        handlePermissionChange(
                          year.id,
                          "gradebook_locked",
                          e.target.checked
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Box>
  );
}
