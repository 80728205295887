import axios from "../utils/axios-util";

const reportsEndpoint = "/api/reports";

const studentsEmergencyContacts = async (params) => {
  const { data } = await axios.get(
    `${reportsEndpoint}/students_emergency_information`,
    { params }
  );

  if (!data || !data.length) {
    console.error("Error fetching emergency contacts from the API");
    return [];
  }
  return data;
};

const klassRoster = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/klass_roster_reports`, {
    params,
  });

  if (!data) {
    console.error("Error fetching class roster from the API");
    return [];
  }
  return data;
};

const assignments = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/assignments`, {
    params,
  });

  if (!data) {
    console.error("Error fetching assignments from the API");
    return [];
  }
  return data;
};

const homeroomRoster = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/homeroom_roster`, {
    params,
  });

  if (!data) {
    console.error("Error fetching homeroom roster from the API");
    return [];
  }
  return data;
};
const calenderReport = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/calender_report`, {
    params,
  });

  if (!data.data) {
    console.error("Error fetching calender report from the API");
    return [];
  }
  return data;
};

const enrollments = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/enrollments`, {
    params,
  });

  if (!data) {
    console.error("Error fetching enrollments from the API");
    return [];
  }
  return data;
};

const parentContacts = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/parent_contacts`, {
    params,
  });

  if (!data) {
    console.error("Error fetching enrollments from the API");
    return [];
  }
  return data;
};

const attendance = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/attendance`, {
    params,
  });

  if (!data) {
    console.error("Error fetching attendance report from the API");
    return [];
  }
  return data;
};

const studentProgress = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/progress_report`, {
    params,
  });

  if (!data) {
    console.error("Error fetching student progress from the API");
    return [];
  }
  return data;
};

const birthdays = async (params) => {
  const { data } = await axios.get(`${reportsEndpoint}/birthdays`, {
    params,
  });

  if (!data) {
    console.error("Error fetching students birthdays from the API");
    return [];
  }
  return data;
};

const parentContactsCSV = async () => {
  const { data } = await axios.get(
    `${reportsEndpoint}/parent_contact_report_csv`
  );

  if (!data) {
    console.error("Error downloading parent contacts CSv file.");
    return [];
  }
  return data;
};

const studentSchoolList = async (params) => {
  const { data } = await axios.get(
    `${reportsEndpoint}/student_school_list`,
    params
  );

  if (!data) {
    console.error("Error fetching student school list from the API");
    return [];
  }
  return data;
};

export default {
  studentsEmergencyContacts,
  klassRoster,
  assignments,
  calenderReport,
  homeroomRoster,
  enrollments,
  attendance,
  parentContacts,
  studentProgress,
  birthdays,
  parentContactsCSV,
  studentSchoolList,
};
