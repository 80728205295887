import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Fade,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import {
  reportCardCheckboxCell,
  reportCardGradeLevelCell,
  reportCardCommentCell,
  reportCardKlassesCellBox,
  reportCardKlassHeaderCell,
  reportCardNameCell,
  reportCardSelectTermBox,
  reportCardStudentsHeader,
  reportCardTable,
  reportCardModalPreview,
  showCommentsDiv,
  reportCardPublishedCell,
} from "./ReportCardTable.styles";
import ReportCardRow from "./ReportCardRow";
import ReportCardSelect from "./ReportCardSelect";
import reportCardsService from "../../service/reportCardsService";
import teacherService from "../../service/staffsService";
import specialMarkService from "../../service/specialMarkService";
import { QuickBarContext } from "../../context/QuickBarContext";
import { SnackbarContext } from "../../context/SnackbarContext";
import ReportCardTKKDrawer from "./ReportCardTKKDrawer";
import subjectService from "../../service/subjectService";
import ReportCardSocialDevelopmentDrawer from "./ReportCardSocialDevelopmentDrawer";
import ReportCardDistributeDrawer from "./ReportCardDistributeDrawer";
import ReportCardDownloadDrawer from "./ReportCardDownloadDrawer";
import ReportCardOneEightDrawer from "./ReportCardOneEightDrawer";
import ReportCardPreview from "./ReportCardPreview";
import { font22, loader } from "../sharedStyles";
import StaffType from "../../utils/constants/staffTypeEnum";
import reportCardCommentService from "../../service/reportCardCommentService";
import prekDevelopmentService from "../../service/preKDevelopmentService";
import ReportCardPrekTable from "./ReportCardPrekTable";
import ReportCardPrekDrawer from "./ReportCardPrekDrawer";

export default function ReportCardTable() {
  const [
    schoolId,
    selectedTerm,
    selectedStudentGradeFilter,
    setSelectedStudentGradeFilter,
    setSelectedTeacherFilter,
    teacherFilterActive,
    setTeacherFilterActive,
    selectedTeacherFilter,
    statusFilterActive,
    setStatusFilterActive,
    selectedStatusFilter,
    setSelectedStatusFilter,
  ] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams({
    comments: true,
  });
  const quickbarContext = useContext(QuickBarContext);
  const snackbarContext = useContext(SnackbarContext);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [students, setStudents] = useState([]);
  const [klasses, setKlasses] = useState([]);
  const [klassesStudents, setKlassesStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [termGrades, setTermGrades] = useState({});
  const [gradeLevels, setGradeLevels] = useState([]);
  const [reportCards, setReportCards] = useState([]);
  const [specialMarks, setSpecialMarks] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [socialDevelopment, setSocialDevelopment] = useState({});
  const [skills, setSkills] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeKlass, setActiveKlass] = useState(false);
  const [activeStudent, setActiveStudent] = useState(false);
  const [activeSocialDevelopment, setActiveSocialDevelopment] = useState(null);
  const [tkkDrawer, setTkkDrawer] = useState(false);
  const [sdDrawer, setSdDrawer] = useState(false);
  const [prekDrawer, setPrekDrawer] = useState(false);
  const [downloadDrawer, setDownloadDrawer] = useState(false);
  const [distributeDrawer, setDistributeDrawer] = useState(false);
  const [oneEightDrawer, setOneEightDrawer] = useState(false);
  const [studentsToDistribute, setStudentsToDistribute] = useState([]);
  const [studentsFailedToDistribute, setStudentsFailedToDistribute] = useState(
    []
  );
  const [activeStudentPreview, setActiveStudentPreview] = useState(null);
  const [showComments, setShowComments] = useState(
    searchParams.get("comments") === "true"
  );
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [prekDomains, setPrekDomains] = useState([]);
  const [prekStudents, setPrekStudents] = useState([]);
  const [selectedPrekStudentDomain, setSelectedPrekStudentDomain] =
    useState(null);
  const [selectedPrekStudent, setSelectedPrekStudent] = useState(null);
  const urlParams = useParams();
  const schoolYearID = urlParams.school_year_id;

  const handleOpenPreview = (studentId) => {
    setOpenPreview(true);
    setActiveStudentPreview(studentId);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
    setActiveStudentPreview(null);
  };

  // Students who's report card isn't available to be published
  const studentsMissingGrades = [];

  const statusFilterValues = [
    // { id: "0", value: "Distributed" },
    // { id: "1", value: "Not Distributed" },
    { id: "2", value: "Published" },
    { id: "3", value: "Not Published" },
  ];

  const getRcComments = async (data) => {
    if (searchParams.get("comments") !== "true") return;

    if (!data) {
      // eslint-disable-next-line no-param-reassign
      data = students;
    }

    const response = await reportCardCommentService.fetchAll({
      params: {
        term_id: selectedTerm.id,
        student_ids: data.map((s) => s.id),
      },
    });
    if (response.data) {
      setComments(response.data);
    }
  };

  const getComment = (student) => {
    const reportCardComment = comments.find(
      (_comment) =>
        _comment.student_id === student.id &&
        _comment.term_id === Number(selectedTerm.id)
    );

    return reportCardComment?.comment || null;
  };

  const getStudents = async () => {
    if (
      selectedTerm !== null &&
      selectedTerm.id &&
      selectedStudentGradeFilter
    ) {
      const response = await reportCardsService.fetchStudents({
        params: {
          school_year_id: schoolYearID,
          school_id: schoolId,
          term_id: selectedTerm.id,
          grade_level: selectedStudentGradeFilter,
          ...(teacherFilterActive && { teacher_id: selectedTeacherFilter }),
          ...(statusFilterActive && { status: selectedStatusFilter }),
        },
      });

      if (response.data) {
        // eslint-disable-next-line array-callback-return
        response.data.map((s, i) => {
          const filter = response.data.filter((f) => f.id === s.id);
          // rm unenrolled student record where is_enrolled = true
          if (filter.length > 1 && s.is_enrolled === true) {
            response.data.splice(i, 1);
          }
        });

        setStudents(response.data);
        await getRcComments(response.data);
      }
    }
  };

  const getKlasses = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchKlasses({
        school_id: schoolId,
        term_id: selectedTerm.id,
        grade_level: selectedStudentGradeFilter,
        ...(teacherFilterActive && { teacher_id: selectedTeacherFilter }),
      });

      if (response.data) {
        setKlasses(response.data);
      }
    }
  };

  const getTermGrades = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchTermGrades({
        params: {
          school_id: schoolId,
          term_id: selectedTerm.id,
        },
      });

      if (response.data) {
        setTermGrades(response.data);
      }
    }
  };

  const getGradeLevels = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchGradeLevels({
        school_year_id: schoolYearID,
        school_id: schoolId,
        term_id: selectedTerm.id,
      });

      if (response.data) {
        if (
          response.data.length > 0 &&
          searchParams.get("gradeLevel") === null
        ) {
          const params = {};
          searchParams.forEach((urlValue, key) => {
            params[key] = urlValue;
          });
          // eslint-disable-next-line prefer-destructuring
          params.gradeLevel = response.data[0];
          setSearchParams(params);
          setSelectedStudentGradeFilter(response.data[0]);
        }

        setGradeLevels(response.data);
      }
    }
  };

  const getReportCards = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchReportCards({
        term_id: selectedTerm.id,
      });

      if (response.data) {
        setReportCards(response.data);
      }
    }
  };

  const getTeachers = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await teacherService.fetchAllStaffs({
        params: { school_id: schoolId, staff_type: StaffType.TEACHER },
      });

      if (response) {
        setTeachers(response);
      }
    }
  };

  const getKlassesStudents = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchKlassesStudents({
        school_year_id: schoolYearID,
        school_id: schoolId,
      });

      if (response.data) {
        setKlassesStudents(response.data);
      }
    }
  };

  const getSkills = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      if (selectedTerm.id) {
        const response = await reportCardsService.fetchStudentSkills({
          school_id: schoolId,
          term_id: selectedTerm.id,
        });

        if (response.data) {
          setSkills(response.data);
        }
      }
    }
  };

  const checkSkills = () => {
    const selectedStudents = isCheck;
    const studentSkills = skills.student_skills_by_student;

    if (studentSkills) {
      // Check if all selected students are present in studentSkills
      const allSelectedStudentsExist = selectedStudents.every(
        (studentId) => studentSkills[studentId] !== undefined
      );

      if (allSelectedStudentsExist) {
        const selectedKlassStudents = isCheck.map(
          (student) => klassesStudents[student]
        );
        const filteredArray = selectedKlassStudents.map((obj) => {
          const newObj = {};
          // eslint-disable-next-line no-restricted-syntax
          for (const classObj of klasses) {
            const classId = classObj.id;
            if (obj[classId]) {
              newObj[classId] = obj[classId];
            }
          }
          return newObj;
        });

        const allChecked = filteredArray.every((obj) =>
          Object.values(obj).every((item) => item.all_skills_scored === true)
        );

        return allChecked;
      }
      return false;
    }

    return true;
  };

  const getSocialDevelopment = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchSocialDevelopment({
        school_id: schoolId,
        term_id: selectedTerm.id,
      });

      if (response.data) {
        setSocialDevelopment(response.data);
      }
    }
  };

  const getSubjects = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await subjectService.fetchAllSubjects({
        params: {
          school_id: schoolId,
        },
      });

      if (response.data) {
        setSubjects(response.data);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await specialMarkService.fetchSchoolSpecialMarks({
        params: {
          school_id: schoolId,
        },
      });
      if (response.data) {
        setSpecialMarks(response.data.special_marks);
      }
    })();
  }, []);

  const studentsGradeLevels = () =>
    gradeLevels
      .filter((value, index, array) => array.indexOf(value) === index)
      .map((value) => ({ id: value, value }));

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    if (selectedStudentGradeFilter !== "PK") {
      setIsCheck(students.map((student) => student.id));
    } else {
      setIsCheck(prekStudents.map((student) => student.id));
    }
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const fetchPrekDomains = async () => {
    const response = await prekDevelopmentService.fetchPrekDomains();

    if (response.data) {
      setPrekDomains(response.data);
    }
  };

  const fetchPrekStudents = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await prekDevelopmentService.fetchStudents(
        selectedTerm.id,
        teacherFilterActive,
        selectedTeacherFilter,
        selectedStatusFilter
      );

      if (response.data) {
        setPrekStudents(response.data);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getGradeLevels();
      await getSubjects();
    })();
  }, [selectedTerm]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (selectedStudentGradeFilter !== "PK") {
        await getStudents();
        await getKlasses();
        await getTermGrades();
        await getKlassesStudents();
        await getReportCards();
        await getSkills();
        await getSocialDevelopment();
      } else {
        await fetchPrekDomains();
        await fetchPrekStudents();
      }
      await getTeachers();
      setLoading(false);
    })();
  }, [
    selectedTerm,
    selectedStudentGradeFilter,
    selectedTeacherFilter,
    selectedStatusFilter,
    teacherFilterActive,
    statusFilterActive,
  ]);

  const handlePublishReportCards = async () => {
    if (selectedStudentGradeFilter !== "PK") {
      if (!checkSkills()) {
        snackbarContext.setSnackbar({
          message: "Students have missing skills",
          severity: "error",
          open: true,
        });

        return;
      }
    }

    if (isCheck.length === 0) {
      snackbarContext.setSnackbar({
        message: "No report cards selected to publish.",
        severity: "error",
        open: true,
      });

      return;
    }

    let studentsToPublish = [];
    if (selectedStudentGradeFilter !== "PK") {
      studentsToPublish = isCheck
        .map((studentIDChecked) => {
          const studentMissesGrades = studentsMissingGrades.find(
            (smg) => smg === studentIDChecked
          );

          if (studentMissesGrades) {
            return null;
          }

          return studentIDChecked;
        })
        .filter((studentID) => studentID !== null);
    } else {
      const rows = document.getElementsByClassName("prek-tbody")[0].children;
      for (let i = 0; i < rows.length; i += 1) {
        const cells = rows[i].children;
        if (!isCheck.includes(parseInt(cells[0].id, 10))) {
          // eslint-disable-next-line no-continue
          continue;
        }
        let postedDomainCount = 0;
        for (let j = 4; j < cells.length; j += 1) {
          if (cells[j].querySelector(".posted")) {
            postedDomainCount += 1;
          }
        }
        if (postedDomainCount === 5) {
          studentsToPublish.push(cells[0].id);
        }
      }
    }

    if (studentsToPublish.length === 0) {
      snackbarContext.setSnackbar({
        message: "Report Cards can't be published, missing class grades.",
        severity: "error",
        open: true,
      });

      return;
    }

    if (studentsToPublish.length !== isCheck.length) {
      await reportCardsService.publishReportCards({
        student_ids: studentsToPublish,
        term_id: selectedTerm.id,
        grade_level: selectedStudentGradeFilter,
      });

      snackbarContext.setSnackbar({
        message: "One or more students are missing class grades.",
        severity: "warning",
        open: true,
      });
    } else if (studentsToPublish.length > 0) {
      await reportCardsService.publishReportCards({
        student_ids: studentsToPublish,
        term_id: selectedTerm.id,
        grade_level: selectedStudentGradeFilter,
      });

      snackbarContext.setSnackbar({
        message: "Report Cards Published.",
        severity: "success",
        open: true,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (quickbarContext.publishReportCards === true) {
        await handlePublishReportCards();
        quickbarContext.cleanPublishReportCards();
        if (selectedStudentGradeFilter !== "PK") {
          await getReportCards();
        } else {
          await fetchPrekStudents();
        }
      }
    })();
  }, [quickbarContext.publishReportCards]);

  const handleUnpublishReportCards = async () => {
    if (isCheck.length === 0) {
      snackbarContext.setSnackbar({
        message: "No report cards selected to unpublish.",
        severity: "error",
        open: true,
      });

      return;
    }

    await reportCardsService.unpublishReportCards({
      student_ids: isCheck,
      term_id: selectedTerm.id,
    });

    snackbarContext.setSnackbar({
      message: "Report cards unpublished.",
      severity: "success",
      open: true,
    });
  };

  useEffect(() => {
    (async () => {
      if (quickbarContext.unpublishReportCards === true) {
        await handleUnpublishReportCards();
        quickbarContext.cleanUnpublishReportCards();
        if (selectedStudentGradeFilter !== "PK") {
          await getReportCards();
        } else {
          await fetchPrekStudents();
        }
      }
    })();
  }, [quickbarContext.unpublishReportCards]);

  const handleDistributeReportCards = async () => {
    if (isCheck.length === 0) {
      snackbarContext.setSnackbar({
        message: "No report cards selected to distribute.",
        severity: "error",
        open: true,
      });

      return;
    }

    const studentsNotPublished = [];
    const studentsPublished = [];

    const studentsReady = isCheck
      .map((studentID) => {
        if (!reportCards[studentID]) {
          studentsNotPublished.push(studentID);
          return null;
        }

        return reportCards[studentID];
      })
      .filter((student) => student !== null);

    studentsReady.forEach((student) => {
      if (!student.is_published || student.is_distributed) {
        studentsNotPublished.push(student.student_id);
        return;
      }

      studentsPublished.push(student);
    });

    if (studentsPublished.length === 0) {
      snackbarContext.setSnackbar({
        message: "Report Cards selected can't be distributed.",
        severity: "error",
        open: true,
      });

      return;
    }

    const studentsDistribute = [];

    const studentsFailed = [];

    studentsPublished.forEach((sr) => {
      studentsDistribute.push(students.find((s) => sr.student_id === s.id));
    });

    studentsNotPublished.forEach((sr) => {
      studentsFailed.push(students.find((s) => sr === s.id));
    });

    setStudentsFailedToDistribute(studentsFailed);
    setStudentsToDistribute(studentsDistribute);
    setDistributeDrawer(true);
    setDrawerOpen(true);
  };

  useEffect(() => {
    (async () => {
      if (quickbarContext.distributeReportCards === true) {
        await handleDistributeReportCards();
        quickbarContext.cleanDistributeReportCards();
        await getReportCards();
      }
    })();
  }, [quickbarContext.distributeReportCards]);

  const handleDownloadReportCards = async () => {
    if (selectedTerm === null || !selectedTerm.id) {
      snackbarContext.setSnackbar({
        message: "Please select term.",
        severity: "error",
        open: true,
      });

      return;
    }

    setDrawerOpen(true);
    setDownloadDrawer(true);
  };

  useEffect(() => {
    (async () => {
      if (quickbarContext.downloadReportCards === true) {
        await handleDownloadReportCards();
        quickbarContext.cleanDownloadReportCards();
      }
    })();
  }, [quickbarContext.downloadReportCards]);

  const handleDrawerClose = async () => {
    setActiveKlass(null);
    setActiveStudent(null);
    setDrawerOpen(false);
    setTkkDrawer(false);
    setSdDrawer(false);
    setPrekDrawer(false);
    setOneEightDrawer(false);
    setDistributeDrawer(false);
    setDownloadDrawer(false);
    setStudentsToDistribute([]);
    setStudentsFailedToDistribute([]);
    await getReportCards();
  };

  const handleDrawerOpen = (klass, student, socialDevelopmentSkills) => {
    if (selectedStudentGradeFilter !== "PK") {
      setActiveKlass(klass);
      setActiveStudent(student);
      setActiveSocialDevelopment(socialDevelopmentSkills);
    }
    setDrawerOpen(true);
  };

  if (selectedTerm === null || !selectedTerm.id) {
    return (
      <Box sx={reportCardSelectTermBox}>
        <Typography sx={font22}>
          Please select a term above to begin reviewing report cards.
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
        <ReportCardSelect
          label="Student Grade Level"
          values={studentsGradeLevels()}
          isActive
          value={selectedStudentGradeFilter}
          setValue={setSelectedStudentGradeFilter}
          urlParam="gradeLevel"
          hasCheckmark={false}
          urlEnabledParam="gradeLevelFilterActive"
          allEnabled={false}
          setIsCheck={setIsCheck}
          setIsCheckAll={setIsCheckAll}
        />
        <ReportCardSelect
          label="Teacher"
          values={teachers.map((teacher) => ({
            id: teacher.id,
            value: `${teacher.first_name} ${teacher.last_name}`,
          }))}
          isActive={teacherFilterActive}
          setIsActive={setTeacherFilterActive}
          value={selectedTeacherFilter}
          setValue={setSelectedTeacherFilter}
          hasCheckmark
          urlParam="teacher"
          urlEnabledParam="teacherFilterActive"
          allEnabled
        />
        <ReportCardSelect
          label="Status"
          values={statusFilterValues}
          isActive={statusFilterActive}
          setIsActive={setStatusFilterActive}
          value={selectedStatusFilter}
          setValue={setSelectedStatusFilter}
          hasCheckmark
          urlParam="status"
          urlEnabledParam="statusFilterActive"
          allEnabled
        />

        {selectedStudentGradeFilter !== "PK" && (
          <Box
            onClick={async () => {
              setShowComments(!showComments);
              searchParams.set("comments", !showComments);
              setSearchParams(searchParams);
              await getRcComments();
            }}
            sx={showCommentsDiv}
          >
            {showComments ? "Hide" : "Show"} Comments
          </Box>
        )}
      </Box>
      <TableContainer sx={{ mt: 5 }}>
        {selectedStudentGradeFilter !== "PK" ? (
          <Table cellSpacing={0} sx={reportCardTable}>
            <TableHead>
              <TableRow sx={{ paddingTop: "50px" }}>
                <TableCell align="left" sx={reportCardCheckboxCell}>
                  <Box sx={{ maxWidth: "60px" }}>
                    <Checkbox value={isCheckAll} onChange={handleSelectAll} />
                  </Box>
                </TableCell>
                <TableCell align="left" sx={reportCardNameCell}>
                  <Box sx={{ width: "100px", marginLeft: 2 }}>
                    <Typography sx={reportCardStudentsHeader}>
                      Students
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={reportCardGradeLevelCell} align="center">
                  <Box>
                    <Typography>Grade Level</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={reportCardPublishedCell} align="center">
                  <Box sx={reportCardKlassesCellBox}>
                    <Typography>Published</Typography>
                  </Box>
                </TableCell>
                {showComments && (
                  <TableCell sx={reportCardCommentCell} align="center" />
                )}
                {klasses.map((klass) => (
                  <TableCell
                    key={klass.id}
                    sx={reportCardKlassHeaderCell}
                    align="center"
                  >
                    <Box sx={reportCardKlassesCellBox}>
                      <Typography>{klass.name}</Typography>
                    </Box>
                  </TableCell>
                ))}
                <TableCell sx={reportCardKlassHeaderCell} align="center">
                  <Box sx={reportCardKlassesCellBox}>
                    <Typography>Social Development</Typography>
                  </Box>
                </TableCell>
                {/* <TableCell sx={reportCardKlassHeaderCell} align="center">
                <Box sx={reportCardKlassesCellBox}>
                  <Typography>Distr.</Typography>
                </Box>
              </TableCell> */}
                <TableCell sx={reportCardKlassHeaderCell} align="center">
                  <Box sx={reportCardKlassesCellBox} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student) => (
                <ReportCardRow
                  key={student.id}
                  student={student}
                  klasses={klasses}
                  termGrades={termGrades}
                  specialMarks={specialMarks}
                  isCheck={isCheck}
                  setIsCheck={setIsCheck}
                  klassesStudents={klassesStudents}
                  reportCards={reportCards}
                  skills={skills}
                  studentsMissingGrades={studentsMissingGrades}
                  socialDevelopment={socialDevelopment}
                  handleDrawerOpen={handleDrawerOpen}
                  setActiveKlass={setActiveKlass}
                  setActiveStudent={setActiveStudent}
                  setTkkDrawer={setTkkDrawer}
                  setSdDrawer={setSdDrawer}
                  setOneEightDrawer={setOneEightDrawer}
                  snackbarContext={snackbarContext}
                  handleOpenPreview={handleOpenPreview}
                  showComments={showComments}
                  comment={getComment(student)}
                  getRcComments={getRcComments}
                  selectedTerm={selectedTerm}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <ReportCardPrekTable
            isCheckAll={isCheckAll}
            handleSelectAll={handleSelectAll}
            prekDomains={prekDomains}
            prekStudents={prekStudents}
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            handleDrawerOpen={handleDrawerOpen}
            setPrekDrawer={setPrekDrawer}
            setSelectedPrekStudent={setSelectedPrekStudent}
            setSelectedPrekStudentDomain={setSelectedPrekStudentDomain}
            selectedTerm={selectedTerm}
            handleOpenPreview={handleOpenPreview}
          />
        )}
      </TableContainer>
      <Drawer anchor="right" open={drawerOpen}>
        {tkkDrawer ? (
          <ReportCardTKKDrawer
            student={activeStudent}
            klass={activeKlass}
            subjects={subjects}
            teachers={teachers}
            skills={skills}
            handleDrawerClose={handleDrawerClose}
            termGrades={termGrades}
          />
        ) : null}
        {sdDrawer ? (
          <ReportCardSocialDevelopmentDrawer
            student={activeStudent}
            teachers={teachers}
            socialDevelopment={activeSocialDevelopment}
            socialDevelopmentSkills={socialDevelopment}
            handleDrawerClose={handleDrawerClose}
            term={selectedTerm}
          />
        ) : null}
        {prekDrawer ? (
          <ReportCardPrekDrawer
            selectedPrekStudent={selectedPrekStudent}
            selectedPrekStudentDomain={selectedPrekStudentDomain}
            term={selectedTerm}
            handleDrawerClose={handleDrawerClose}
          />
        ) : null}
        {distributeDrawer ? (
          <ReportCardDistributeDrawer
            handleDrawerClose={handleDrawerClose}
            isCheck={isCheck}
            students={studentsToDistribute}
            studentsFailed={studentsFailedToDistribute}
            term={selectedTerm}
          />
        ) : null}
        {oneEightDrawer ? (
          <ReportCardOneEightDrawer
            handleDrawerClose={handleDrawerClose}
            student={activeStudent}
            teachers={teachers}
            term={selectedTerm}
            klass={activeKlass}
            termGrades={termGrades}
            subjects={subjects}
            skills={skills}
            specialMarks={specialMarks}
          />
        ) : null}
        {downloadDrawer ? (
          <ReportCardDownloadDrawer
            handleDrawerClose={handleDrawerClose}
            term={selectedTerm}
            schoolId={schoolId}
            teacherFilterActive={teacherFilterActive}
            teacherId={selectedTeacherFilter}
          />
        ) : null}
      </Drawer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPreview}
        onClose={handleClosePreview}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openPreview}>
          <Box sx={reportCardModalPreview}>
            <ReportCardPreview
              studentIds={
                selectedStudentGradeFilter !== "PK"
                  ? students.map((s) => s.id)
                  : prekStudents.map((s) => s.id)
              }
              activeStudentPreview={activeStudentPreview}
              handleClose={handleClosePreview}
              selectedStudentGradeFilter={selectedStudentGradeFilter}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
