import { Box, Button, Divider, Drawer, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { Add } from "@mui/icons-material";
import ProcessInstance from "./ProcessOverview/ProcessInstance";
import messagesService from "../../../../service/messagesService";
import { formatDateTime } from "../../../../utils/constants/utils";
import {
  buttonWithStartIcon,
  rightDrawerContainer,
} from "../../../sharedStyles";
import NewMessage from "../../../Messages/NewMessage/NewMessage";
import SnackBarNotification from "../../../SnackBarNotification";
import studentsService from "../../../../service/studentsService";
import {
  msgGrid,
  msgItemGrid,
  newMsgGrid,
  msgList,
} from "./ProcessOverview.style";

export default function ProcessOverview() {
  const schoolYearID = useParams().school_year_id;
  const [processInstance, guardians, setProcessInstance, currentStaffId] =
    useOutletContext();
  const [messages, setMessages] = useState([]);
  const [messageDrawerStatus, setMessageDrawerStatus] = useState(false);
  const params = useParams();
  const schoolId = params.school_id;
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [messageParentIds, setMessageParentIds] = useState([]);
  const [searchParams] = useSearchParams();
  const [studentDetails, setStudentDetails] = useState(null);

  const handleMessagesDrawer = (status) => {
    setMessageDrawerStatus(status);
    setMessageParentIds(
      studentDetails.guardians_students?.map((gs) => gs.guardian.id) || null
    );
  };

  useEffect(() => {
    studentsService
      .fetchStudentDetails(searchParams.get("student_id"))
      .then((response) => {
        setStudentDetails(response.data);
      });
  }, []);

  useEffect(() => {
    if (guardians && guardians[0] && messages.length === 0)
      messagesService
        .recipientsMessages(guardians.map((g) => g.id))
        .then((response) => {
          setMessages(response.data.filter((f) => f.conversation_key !== null));
        });
  }, [guardians]);

  const guardianName = (recipientId) => {
    const guardian = guardians.find((g) => g.id === recipientId);
    return `${guardian?.first_name} ${guardian?.last_name}`;
  };

  return (
    <Grid container columnSpacing={2}>
      {" "}
      <Grid item md={8}>
        <ProcessInstance
          processInstance={processInstance}
          setProcessInstance={setProcessInstance}
          currentStaffId={currentStaffId}
        />
      </Grid>
      <Grid item md={4} sx={msgGrid}>
        <Grid item md={6} sx={msgItemGrid}>
          <Typography fontSize={18}>Messages</Typography>
        </Grid>
        <Grid item md={6} sx={newMsgGrid}>
          <Button
            sx={buttonWithStartIcon}
            startIcon={<Add />}
            onClick={() => handleMessagesDrawer(true)}
          >
            New Message
          </Button>
        </Grid>
        <Grid item md={12} container mt={3}>
          {messages.map((message, index) => (
            <>
              <Grid
                item
                md={12}
                p={1}
                border="1px solid #F6F6F6"
                sx={msgList}
                onClick={() =>
                  window.open(
                    `/school/${schoolId}/${schoolYearID}/messages/conversation/${message.conversation_key}`,
                    "_blank"
                  )
                }
              >
                <Typography fontSize={14}>
                  {guardianName(message.recipient_id)}
                </Typography>
                <Typography fontSize={14}>
                  <strong>{message.subject}</strong>
                </Typography>
                <Typography fontSize={14}>
                  <strong>Date:</strong>{" "}
                  {formatDateTime(message?.recent_message?.created_at)}
                </Typography>
              </Grid>
              {index !== messages.length - 1 && (
                <Grid item container md={12}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      height: "1rem",
                      width: "1px",
                      ml: "0.85rem",
                    }}
                  />
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Grid>
      <Drawer anchor="right" open={messageDrawerStatus}>
        <Box sx={rightDrawerContainer}>
          <NewMessage
            handleMessagesDrawer={handleMessagesDrawer}
            schoolId={schoolId}
            setSnackBarOpen={setSnackBarOpen}
            messageDrawerStatus={messageDrawerStatus}
            messageParentIds={messageParentIds}
          />
        </Box>
      </Drawer>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Grid>
  );
}
