import React, { useState, useEffect, useContext } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useFormContext } from "react-hook-form";
import lookupService from "../../../service/lookupService";
import { danger } from "../../sharedStyles";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import ErasableTextField from "../../ErasableTextField";

function Demographics({ student }) {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const [languages, setLanguages] = useState([]);
  const [genders, setGenders] = useState([]);
  const [ethnicities, setEthnicities] = useState([]);
  const [denominations, setDenominations] = useState([]);
  const [baptismYear, setBaptismYear] = useState(student.baptism_year);
  const [selectedDenomination, setSelectedDenomination] = useState(null);
  const [denominationChecked, setDemoninationChecked] = useState(
    Boolean(student.denomination)
  );
  const [baptismYearChecked, setBaptismYearChecked] = useState(
    Boolean(student.baptism_year)
  );
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_ACCOUNT,
    PERMISSION_TYPES.MANAGE
  );

  useEffect(() => {
    setValue("denomination", student.denomination);
    setSelectedDenomination(student.denomination);
    Promise.all([
      lookupService.fetchLanguages(),
      lookupService.fetchGenders(),
      lookupService.fetchSchoolEthnicities(),
      lookupService.fetchDenomination(),
    ]).then((response) => {
      setLanguages(response[0].data.data);
      setGenders(response[1].data.data);
      setEthnicities(response[2].data.data);
      setDenominations(response[3].data.data);
    });
  }, [student]);

  const onBaptismYearHandleChange = (date) => {
    if (date) {
      setBaptismYear(date);
      setValue("baptism_year", date.format("MM/DD/YYYY"), {
        shouldDirty: true,
      });
    }
  };

  return (
    <Grid item xs={12} ml={{ sx: "10%", sm: "5%" }} textAlign="center">
      <Grid item sm={11} mb={4}>
        <Typography variant="h3">Demographics</Typography>
      </Grid>
      <Grid item xs={12} my={1}>
        <Stack direction="column">
          <Box ml={3} mb={2} key="gender">
            <FormControl fullWidth>
              <InputLabel id="genderLabel">Gender</InputLabel>
              <Select
                labelId="genderLabel"
                id="gender"
                label="Gender"
                placeholder="Select"
                defaultValue={student.gender}
                {...register("gender")}
                disabled={!managePermission}
              >
                {genders.map((gender) => (
                  <MenuItem key={gender.id} value={gender.name}>
                    <Typography textAlign="left">{gender.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="ethnicity">
            <FormControl fullWidth>
              <InputLabel id="ethnicityLabel">Ethnicity</InputLabel>
              <Select
                labelId="ethnicityLabel"
                id="ethnicity"
                label="Ethnicity"
                defaultValue={student.ethnicity}
                {...register("ethnicity")}
                disabled={!managePermission}
              >
                {ethnicities.map((ethnicity) => (
                  <MenuItem key={ethnicity.id} value={ethnicity.name}>
                    <Typography textAlign="left">{ethnicity.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="language">
            <FormControl fullWidth>
              <InputLabel id="languageLabel">Language</InputLabel>
              <Select
                labelId="languageLabel"
                id="language"
                label="Language"
                defaultValue={student.language}
                {...register("language")}
                disabled={!managePermission}
              >
                {languages.map((language) => (
                  <MenuItem key={language.id} value={language.name}>
                    <Typography textAlign="left">{language.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="home_church">
            <FormControl fullWidth>
              <ErasableTextField
                id="home_church"
                label="Home Church"
                defaultValue={student.home_church}
                fullWidth
                disabled={!managePermission}
              />
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="denomination">
            <Grid container item sm={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!managePermission}
                      onChange={(event) => {
                        setDemoninationChecked(event.target.checked);
                        if (!event.target.checked) {
                          setValue("denomination", null, { shouldDirty: true });
                          setSelectedDenomination(null);
                        } else if (!getValues("denomination")) {
                          setValue("denomination", "None", {
                            shouldDirty: true,
                          });
                          setSelectedDenomination("None");
                        }
                      }}
                      defaultChecked={denominationChecked}
                    />
                  }
                  label="Is a member of a church"
                />
              </FormGroup>
            </Grid>
            <Grid container item sm={11} xs={12} ml="auto">
              <FormControl fullWidth>
                <InputLabel id="denominationLabel">Denomination</InputLabel>
                <Select
                  labelId="denominationLabel"
                  id="denomination"
                  label="Denomination"
                  value={selectedDenomination || ""}
                  disabled={!managePermission || !denominationChecked}
                  {...register("denomination", {
                    onChange: (e) => {
                      setSelectedDenomination(e.target.value);
                    },
                  })}
                >
                  {denominations.map((denomination) => (
                    <MenuItem key={denomination.id} value={denomination.name}>
                      <Typography textAlign="left">
                        {denomination.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Box>
          <Box ml={3} mb={2} key="baptism_year">
            <Grid container item sm={12}>
              <FormGroup>
                <FormControlLabel
                  sx={{ textAlign: "left" }}
                  control={
                    <Checkbox
                      disabled={!managePermission}
                      onChange={(event) => {
                        setBaptismYearChecked(event.target.checked);
                        if (!event.target.checked) {
                          setBaptismYear(null);
                          setValue("baptism_year", null, { shouldDirty: true });
                        }
                      }}
                      defaultChecked={baptismYearChecked}
                    />
                  }
                  label="Is baptized Seventh-day Adventist"
                />
              </FormGroup>
            </Grid>
            <Grid container item sm={11} xs={12} ml="auto">
              <DesktopDatePicker
                id="baptism_year"
                label="Baptism Date"
                onChange={onBaptismYearHandleChange}
                value={baptismYear}
                disabled={!managePermission || !baptismYearChecked}
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register("baptism_year", {
                      pattern: {
                        value:
                          /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                        message: "Invalid date.",
                      },
                    })}
                  />
                )}
              />
              {errors.baptism_year && (
                <Typography sx={danger}>
                  {errors.baptism_year.message}
                </Typography>
              )}
            </Grid>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Demographics;
