import React, { useState, useContext, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Divider,
  Grid,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { pageHeaderTitle } from "../components/sharedStyles";
import AssignmentsList from "../components/Subject/Assignments/AssignmentsList";
import TermSelect from "../components/TermSelect";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import Authorize from "../components/Authorize";
import schoolYearService from "../service/schoolYearService";

const CssSearchField = styled(TextField)({
  "& .MuiInput-root": {
    marginRight: "17px",
    "&:before": {
      borderBottom: "1px solid #22282C !important",
    },
  },
});

const staffListContainer = (isCompact) => ({
  px: isCompact && "40px !important",
});

export default function Assignments({ isCompact = false }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const schoolId = useParams().school_id;
  const [query, setQuery] = React.useState(searchParams.get("query") || "");
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = React.useState(
    searchParams.get("query") || false
  );
  const [queryChanged, setQueryChanged] = useState(false);
  const params = useParams();
  const { hasAnyPermissionType } = useContext(PermissionsContext);
  const schoolYearID = params.school_year_id;
  const [schoolYears, setSchoolYears] = useState([]);

  const getSchoolYears = async () => {
    const response = await schoolYearService.fetchAll({
      params: {
        school_id: schoolId,
      },
    });
    if (response.data) {
      setSchoolYears(response.data.school_years);
    }
  };

  useEffect(() => {
    getSchoolYears();
  }, []);

  const currentSchoolYear = schoolYears.find(
    (year) => year.id.toString() === schoolYearID
  );

  const handleSearch = (data) => {
    searchParams.set("query", data.search);
    setQuery(data.search);
    setSearchParams(searchParams);
    setQueryChanged(true);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  return (
    <Authorize
      permission={hasAnyPermissionType(APP_PERMISSIONS.KLASSES_ASSIGNMENTS)}
    >
      <div>
        <Container
          maxWidth={false}
          variant="header"
          sx={staffListContainer(isCompact)}
        >
          <Stack
            direction={isCompact ? "column" : "row"}
            alignItems={isCompact ? "baseline" : "center"}
            justifyContent={isCompact ? "start" : "space-between"}
          >
            <Typography sx={pageHeaderTitle}>Assignments</Typography>
            <Grid container justifyContent="end" columnSpacing={4}>
              <Grid item>
                {!isCompact && (
                  <form
                    onSubmit={handleSubmit(handleSearch)}
                    onChange={handleQuickFindChange}
                  >
                    <CssSearchField
                      label="Quick Find"
                      variant="standard"
                      color="primary"
                      fullWidth={!!isCompact}
                      defaultValue={query}
                      {...register("search")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickOnClearButton}
                              sx={cancelButtonStyle}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                            <IconButton type="submit">
                              <SearchIcon size="small" color="primary" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                )}
              </Grid>
              <Grid item>
                <TermSelect
                  schoolId={params.school_id}
                  fetchUpcomingTerms
                  findCurrentTerm
                  showDefault
                  schoolYearID={schoolYearID}
                />
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={{ borderColor: "black" }} />
          <AssignmentsList
            showAll
            query={query}
            setQuery={setQuery}
            queryChanged={queryChanged}
            setQueryChanged={setQueryChanged}
            currentSchoolYear={currentSchoolYear}
          />
        </Container>
      </div>
    </Authorize>
  );
}
