import React from "react";
import { Route } from "react-router-dom";
import Schedules from "../views/Schedules";
import ClassSchedules from "../components/Schedules/ClassSchedules/ClassSchedules";
import Terms from "../components/Schedules/Terms/Terms";
import SchoolDays from "../components/Schedules/SchoolDays/SchoolDays";
import DayTemplates from "../components/Schedules/DayTemplates/DayTemplates";
import ClassScheduleDetails from "../components/Schedules/ClassSchedules/ClassScheduleDetails";

export default [
  <Route
    path="/school/:school_id/:school_year_id/schedules"
    element={<Schedules />}
    key={0}
  >
    <Route path="school-days" element={<SchoolDays />} key={1} />
    <Route path="terms" element={<Terms />} key={2} />
    <Route path="day-templates" element={<DayTemplates />} key={3} />
    <Route path="class-schedules" element={<ClassSchedules />} key={4} />
    <Route
      path="class-schedules/:class_schedule_id/classes"
      element={<ClassScheduleDetails />}
      key={5}
    />
  </Route>,
];
