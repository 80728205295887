import React from "react";
import { Route } from "react-router-dom";
import Staffs from "../views/Staffs";
import StaffMembers from "../views/StaffMembers";
import TemporaryStaffMembers from "../views/TemporaryStaffMembers";

export default [
  <Route
    path="/school/:school_id/:school_year_id/staff"
    element={<Staffs />}
    key={0}
  >
    <Route
      path="/school/:school_id/:school_year_id/staff/staffs"
      element={<StaffMembers />}
      key={1}
    />
    <Route
      path="/school/:school_id/:school_year_id/staff/temporary-staff"
      element={<TemporaryStaffMembers />}
      key={2}
    />
  </Route>,
];
