import React, { useContext, useEffect, useState } from "react";
import {
  Link as RouterLink,
  useParams,
  Outlet,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import {
  Container,
  Typography,
  Link,
  Drawer,
  Box,
  Stack,
  Tab,
  CircularProgress,
  Grid,
} from "@mui/material";
import StudentList from "./StudentList";
import { flex, mr15, loader } from "../components/sharedStyles";
import {
  CssTabs,
  DrawerHeader,
  Main,
  studentArrowIcon,
  studentInfoDrawer,
  studentInfoHighlightedLink,
  studentInfoStackArrowIcon,
  studentInfoTabBorder,
  studentInfoTabBox,
  studentInfoTabContainer,
} from "./StudentInfo.style";
import studentsService from "../service/studentsService";
import StudentActions from "../components/StudentInfo/StudentActions/StudentActions";
import { StudentActionsProvider } from "../context/StudentActionsContext";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import { NavigationContext } from "../context/NavigationContext";

export default function StudentInfo() {
  const params = useParams();
  const location = useLocation();
  const studentSlug = params.student_slug;
  const schoolId = params.school_id;
  const schoolYearID = params.school_year_id;
  const { hasAnyPermissionType } = useContext(PermissionsContext);
  const { navigateTo } = useContext(NavigationContext);
  const [, , , , , , , , , messageDrawerStatus, , currentSchool] =
    useOutletContext();

  const studentAccountPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_ACCOUNT
  );

  const studentProfilePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_PROFILE
  );

  const medicalProfilePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_MEDICAL
  );

  const studentMessagesPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_MESSAGES
  );

  const eventsProfilePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_EVENTS
  );

  const portfolioFilesPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_PORTFOLIO
  );

  const transcriptPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_TRANSCRIPTS
  );

  const tabs = [
    ...(studentAccountPermission
      ? [
          {
            label: "Account",
            url: `/school/${schoolId}/${schoolYearID}/students/${studentSlug}/account`,
            permission: studentAccountPermission,
          },
        ]
      : []),
    ...(studentProfilePermission
      ? [
          {
            label: "Profile",
            url: `/school/${schoolId}/${schoolYearID}/students/${studentSlug}/profile`,
            permission: studentProfilePermission,
          },
        ]
      : []),
    ...(medicalProfilePermission
      ? [
          {
            label: "Medical",
            url: `/school/${schoolId}/${schoolYearID}/students/${studentSlug}/medical`,
            permission: medicalProfilePermission,
          },
        ]
      : []),
    ...(eventsProfilePermission
      ? [
          {
            label: "Events",
            url: `/school/${schoolId}/${schoolYearID}/students/${studentSlug}/events`,
            permission: eventsProfilePermission,
          },
        ]
      : []),
    ...(studentMessagesPermission
      ? [
          {
            label: "Messages",
            url: `/school/${schoolId}/${schoolYearID}/students/${studentSlug}/messages`,
          },
        ]
      : []),
    ...(portfolioFilesPermission
      ? [
          {
            label: "Portfolio",
            url: `/school/${schoolId}/${schoolYearID}/students/${studentSlug}/portfolio`,
          },
        ]
      : []),
    ...(transcriptPermission && currentSchool.has_secondary_students
      ? [
          {
            label: "Transcripts",
            url: `/school/${schoolId}/${schoolYearID}/students/${studentSlug}/transcripts`,
            permission: transcriptPermission,
          },
        ]
      : []),
  ].map((tab, index) => ({ ...tab, index }));

  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(tabs[0]);
  const [student, setStudent] = useState(null);

  const handleTabChange = (path) => {
    navigateTo(path);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    (async () => {
      setStudent(null);
      const response = await studentsService.fetchStudent(studentSlug, {
        params: { school_id: schoolId },
      });
      setStudent(response);
    })();
  }, [studentSlug]);

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url)
    );

    setTabValue(getTabFromUrl || tabs[0]);
  }, [location]);

  return (
    <Box sx={flex}>
      <Drawer
        sx={studentInfoDrawer}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <DoubleArrowRoundedIcon
            size="small"
            onClick={handleDrawerToggle}
            sx={studentArrowIcon}
          />
        </DrawerHeader>

        <Box>
          <StudentList isCompact />
        </Box>
      </Drawer>
      <StudentActionsProvider>
        <Main open={open}>
          <Container maxWidth={false} variant={open ? "container" : "header"}>
            <Stack direction="row" justifyContent="space-between">
              <div>
                <DoubleArrowRoundedIcon
                  size="small"
                  onClick={handleDrawerToggle}
                  sx={studentInfoStackArrowIcon(open)}
                />
                <Stack direction="row" alignItems="end">
                  <Typography variant="h1" color="#000000" sx={mr15}>
                    {student
                      ? `${student.first_name} ${student.middle_name || ""} ${
                          student.last_name
                        }`
                      : "Loading..."}
                  </Typography>
                </Stack>
              </div>
              <Link
                to={`/school/${schoolId}/students`}
                underline="hover"
                as={RouterLink}
                sx={studentInfoHighlightedLink}
              >
                <ArrowBackRoundedIcon size="small" />
                Return to Student List
              </Link>
            </Stack>
          </Container>

          <Box sx={studentInfoTabBox}>
            <Container maxWidth={false} sx={studentInfoTabContainer(open)}>
              <Box sx={studentInfoTabBorder}>
                <CssTabs value={tabValue?.index}>
                  {tabs?.map(
                    (tab) =>
                      (tab.permission === undefined || tab.permission) && (
                        <Tab
                          key={tab.index}
                          label={tab.label}
                          onClick={() => handleTabChange(tab.url)}
                        />
                      )
                  )}
                </CssTabs>
              </Box>
            </Container>
          </Box>

          <Grid container justifyContent="center">
            {student ? (
              <Outlet
                context={[student, open, messageDrawerStatus, currentSchool]}
              />
            ) : (
              <CircularProgress sx={loader} size={100} />
            )}
          </Grid>

          <StudentActions />
        </Main>
      </StudentActionsProvider>
    </Box>
  );
}
