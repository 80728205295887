import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import reportsService from "../../../service/reportsService";
import StudentGrades from "./StudentGrades";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import KlassComments from "./KlassComments";
import specialMarkService from "../../../service/specialMarkService";
import StudentProgressHeader from "./StudentProgressHeader";
import { convertToOrdinal } from "../../../utils/constants/utils";

export default function StudentProgress() {
  const [students, setStudents] = useState([]);
  const [headerData, setHeaderData] = useState(null);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [schoolName, , selectedTeacherStudent, , , , , , , schoolOrgCode] =
    useOutletContext();
  const [loading, setLoading] = useState(false);
  const schoolId = params.school_id;
  const schoolYearID = params.school_year_id;
  const [specialMarks, setSpecialMarks] = useState(null);
  const homeroomTeacherId = searchParams.get("homeroomTeacherId");
  const termId = searchParams.get("term");

  useEffect(() => {
    (async () => {
      setLoading(true);
      reportsService
        .studentProgress({
          school_id: params.school_id,
          school_year_id: schoolYearID,
          homeroom_teacher_id: homeroomTeacherId,
          term_id: termId,
          student_id:
            selectedTeacherStudent !== "all" ? selectedTeacherStudent : null,
        })
        .then((response) => {
          setStudents(
            response.students.filter((student) => student.klasses.length > 0)
          );
          setHeaderData({
            yearName: response.school_year,
            termName: response.term_name,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    })();
  }, [homeroomTeacherId, selectedTeacherStudent, termId, schoolYearID]);

  useEffect(() => {
    (async () => {
      const response = await specialMarkService.fetchSchoolSpecialMarks({
        params: {
          school_id: schoolId,
        },
      });
      if (response.data) {
        setSpecialMarks(response.data.special_marks);
      }
    })();
  }, []);

  const showCommentsSection = (klasses) =>
    klasses.filter(
      (klass) => klass.comments?.length || klass.missing_assignments.length
    ).length > 0;

  const generateBodyContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <Typography color="black" fontSize="20px" mt={5}>
            Loading...
          </Typography>
        </Box>
      );
    }

    if (students.length === 0) {
      return (
        <tr>
          <td>
            <Box textAlign="center" mt={3}>
              No progress report found.
            </Box>
          </td>
        </tr>
      );
    }

    return students.map((student, index) => (
      <tr className="report-data student-progress">
        <td
          className="report-content-cell"
          style={{ padding: "2rem 1rem 0 2rem" }}
        >
          <div>
            <Grid pt={3}>
              <Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  borderBottom="1px solid #8F8F8F"
                >
                  <Grid item>
                    <Typography fontWeight={400} fontSize="24px" color="black">
                      {student.last_name}, {student.first_name}
                    </Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="center" mr={5}>
                    <Typography fontWeight={400} fontSize="12px" color="black">
                      {convertToOrdinal(student.grade_level)} Grade
                    </Typography>
                    <Typography px={2}>|</Typography>
                    <Typography fontWeight={400} fontSize="12px" color="black">
                      Date:{" "}
                      {new Date().toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={5}>
                    <StudentGrades
                      klasses={student.klasses}
                      specialMarks={specialMarks}
                    />
                  </Grid>
                  <Grid item md={7}>
                    {showCommentsSection(student.klasses) && (
                      <KlassComments klasses={student.klasses} />
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    md={9}
                    borderTop="1px solid #000000"
                    mt={10}
                  >
                    <Grid item sm={6} fontSize="0.9rem">
                      Parent Signature
                    </Grid>
                    <Grid item sm={6} fontSize="0.9rem">
                      Date
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {index < students.length - 1 && (
                <div style={{ pageBreakBefore: "always" }} />
              )}
            </Grid>
          </div>
        </td>
      </tr>
    ));
  };

  const generateInitialContent = (
    <Box sx={{ textAlign: "center", mt: "100px" }}>
      <Typography>Please select a teacher</Typography>
    </Box>
  );

  return (
    <PrintLayout
      headerContent={
        loading ? (
          ""
        ) : (
          <StudentProgressHeader
            headerData={headerData}
            schoolName={schoolName}
            schoolOrgCode={schoolOrgCode}
          />
        )
      }
      bodyContent={
        searchParams.get("homeroomTeacherId")
          ? generateBodyContent()
          : generateInitialContent
      }
      footerContent={schoolName}
    />
  );
}
