import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { CircularProgress, Grid } from "@mui/material";
import { useOutletContext, useParams } from "react-router-dom";
import Demographics from "./Demographics";
import ParentSection from "./ParentSection";
import EmergencyContacts from "./EmergencyContacts/EmergencyContacts";
import {
  accountHorizontalContainer,
  accountSecondarySections,
  accountVerticalContainer,
} from "./StudentInfoAccount.style";
import GeneralInfoAccount from "./GeneralInfoAccount";
import { loader } from "../../sharedStyles";
import ContactInformation from "./ContactInformation";
import studentsService from "../../../service/studentsService";
import AutoSave from "../../AutoSave";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import Authorize from "../../Authorize";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

function StudentInfoAccount() {
  const [currentGuardianId, setCurrentGuardianId] = useState(0);
  const [residesWith, setResidesWith] = useState([]);
  const methods = useForm({ mode: "onChange" });
  const [, open] = useOutletContext();
  const params = useParams();
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_ACCOUNT,
    PERMISSION_TYPES.MANAGE
  );

  const accountPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_ACCOUNT
  );

  const [student, setStudent] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await studentsService.fetchAccountDetails(
        params.student_slug,
        {
          params: {
            school_id: params.school_id,
            school_year_id: params.school_year_id,
          },
        }
      );
      setStudent(response);
    })();
  }, []);

  useEffect(() => {
    methods.trigger();
  }, [student]);

  return (
    <Authorize permission={accountPermission}>
      <FormProvider {...methods}>
        {student ? (
          <Grid
            container
            item
            justifyContent="space-between"
            sx={accountHorizontalContainer}
            md={open ? 12 : 9.5}
            paddingX={1}
          >
            <Grid container item marginY={5} xs={12} sm={12} md={12}>
              <GeneralInfoAccount student={student} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              sx={accountSecondarySections}
            >
              <Grid
                container
                item
                direction="row"
                xs={12}
                sm={12}
                md={9}
                sx={accountVerticalContainer}
                alignContent="flex-start"
              >
                <Grid container item direction="column" rowSpacing={3}>
                  <ContactInformation
                    student={student}
                    residesWith={residesWith}
                    setResidesWith={setResidesWith}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={11} md={3}>
                <Demographics student={student} />
              </Grid>
            </Grid>
            <Grid item sm={12} sx={accountSecondarySections}>
              <ParentSection
                currentGuardianId={currentGuardianId}
                setCurrentGuardianId={setCurrentGuardianId}
                setResidesWith={setResidesWith}
              />
            </Grid>
            <Grid item sm={12} sx={accountSecondarySections}>
              <EmergencyContacts
                student={student}
                currentGuardianId={currentGuardianId}
              />
            </Grid>
            {methods.formState.isDirty && managePermission && (
              <AutoSave
                saveMethod={studentsService.update}
                params={[student.id]}
                successMessage="Account saved"
                progressMessage="Saving account..."
              />
            )}
          </Grid>
        ) : (
          <CircularProgress sx={loader} size={100} />
        )}
      </FormProvider>
    </Authorize>
  );
}

export default StudentInfoAccount;
